

function FileUploadIcon({ size = 24 }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" fill='#EEEBF0'  >
        <path d="M4.5 9H13.5" stroke="#574766" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 13.5V4.5" stroke="#574766" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
    )
  }
  export default FileUploadIcon