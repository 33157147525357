import toast from "react-hot-toast";

export function isPercentage(value) {
    // Regular expression to match a percentage value
    const percentageRegex = /^(\d+(\.\d+)?%$)|^100%$/;

    return percentageRegex.test(value);
}
export function toastSuccess(message) {
    toast.success(message);
}

export function toastError(error) {
    toast.error(error.response?.data?.message || error.response?.message || error.message);
}