import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/material/styles';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import Translations from 'src/layouts/components/Translations';
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation';

const validationSchema = (boothPricingCategoryList) => yup.object().shape({
    visits: yup.number().typeError("Number of Visits is required").required("Number of Visits is required"),
    servicesChecked: yup.boolean().oneOf([true], "At least one service must be checked"),
});

function ByopPlan({ packages, setSelectedPlanDetails }) {
    const theme = useTheme();
    const translation = useCustomTranslation()

    const [bulletServices, setBulletServices] = useState(null);
    const [bulletDescription, setBulletDescription] = useState('');
    const [mainServices, setMainServices] = useState(null);
    const [packs, setPacks] = useState([]);
    const [packsDescription, setPacksDescription] = useState('');
    const [extraInputValues, setExtraInputValues] = useState({});
    const [checkedItems, setCheckedItems] = useState({});
    const [manualInputValues, setManualInputValues] = useState({});

    const handleExtraInputChange = (e, serviceName) => {
        setExtraInputValues(prevState => ({
            ...prevState,
            [serviceName]: e.target.value,
        }));
    };

    const handleCheckboxChange = (e, title) => {
        const isChecked = e.target.checked;
        setCheckedItems(prevState => ({
            ...prevState,
            [title]: isChecked,
        }));
    };

    const handleManualInputChange = (e, title) => {
        const newValue = parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0; // Prevent negative values
        setManualInputValues(prevValues => ({
            ...prevValues,
            [title]: newValue,
        }));
    };

    const handleDecrement = (title) => {
        const newValue = (manualInputValues[title] || 0) - 1;
        if (newValue >= 0) {
            setManualInputValues(prevValues => ({
                ...prevValues,
                [title]: newValue,
            }));
        }
    };

    const handleIncrement = (title) => {
        setManualInputValues(prevValues => ({
            ...prevValues,
            [title]: (manualInputValues[title] || 0) + 1,
        }));
    };


    const {
        control,
        setError,
        clearErrors,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            visits: "",
            servicesChecked: false,
        },
        resolver: yupResolver(validationSchema()),
        mode: 'onChange',
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const handleChange = (value) => {
        const currentIndex = selectedValues.indexOf(value);
        const newValues = [...selectedValues];

        if (currentIndex === -1) {
            newValues.push(value);
        } else {
            newValues.splice(currentIndex, 1);
        }
        setSelectedValues(newValues);
    };

    useEffect(() => {
        if (packages?.length > 0) {
            setBulletDescription(packages[0]?.bullet_plan?.description)
            setBulletServices(packages[0]?.bullet_plan?.services?.length > 0 ? packages[0]?.bullet_plan?.services : null)
            setMainServices(packages[0]?.services)
            setPacks(packages[0]?.packages?.packages_item)
            setPacksDescription(packages[0]?.packages?.description)

            if (packages[0]?.services) {
                const initialValues = {};
                packages[0]?.services.forEach(service => {
                    initialValues[service.title] = service.visit || 0;
                });
                setManualInputValues(initialValues);
            }
        }
    }, [packages]);

    useEffect(() => {
        const selectedValue = manualInputValues

        // Find the matching service and update visit and is_visit
        const updatedService = mainServices?.length > 0 && mainServices.map(service => {
            if (selectedValue.hasOwnProperty(service.title)) {
                service.visit = parseInt(selectedValue[service.title]);
                service.is_visit = true;
                return service;
            }
            return null; // Return null for non-matching services
        }).filter(service => service !== null); // Filter out non-matching services


        // const selectedPackage = selectedValues;
        // const selectedObject = packs.find(item => selectedPackage.includes(item.title));
        // const result = selectedObject ? [selectedObject] : [];

        const selectedPackage = selectedValues;
        const selectedObject = packs?.find(item => selectedPackage.includes(item.title));
        const result = selectedObject
            ? [
                {
                    package_name: selectedObject.title,
                    title_name: selectedObject.services.map(service => ({ title: service.title }))
                }
            ]
            : [];


        const updatedMainService = updatedService?.length > 0 ? updatedService?.map(({ service_id, _id, ...rest }) => rest) : null;
        const updatedExtraService = result?.length > 0 ? result?.map(({ service_id, _id, ...rest }) => rest) : null;
        const updatedBulletPlan = bulletServices?.length > 0 ? bulletServices.map(({ service_id, _id, ...rest }) => rest) : null;

        const updatedServices = {
            extraService: updatedExtraService,
            mainService: updatedMainService,
            bulletPlan: updatedBulletPlan
        };

        setSelectedPlanDetails(updatedServices)
    }, [bulletServices, selectedValues, manualInputValues, setSelectedPlanDetails, mainServices, packs]);


    return (
        <>
            <Grid container xs={12} spacing={6} mt={5}>
                <Grid item xs={12}>
                    <Typography variant="fm-h6" color='neutral.80' fontWeight={500}>
                        <Translations text="Build Your Own Plan Details" />
                    </Typography>
                    <Card sx={{ borderRadius: "12px", mt: 5 }}>
                        <CardContent >
                            <Grid container spacing={6}>
                                <Grid item xs={12} >
                                    <Typography variant="fm-p2" color='neutral.70' fontWeight={400}>
                                        {bulletDescription}
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} md={6} spacing={2}>
                                    {bulletServices?.length > 0 && bulletServices.map((item, index) => (
                                        <Grid key={index} item xs={12}>
                                            <CircleIcon key={index} sx={{ fontSize: "12px", marginRight: '4px', color: theme.palette.primary.main }} />
                                            <Typography key={index} variant="fm-p2" color='neutral.90'>{item.title}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item container xs={12} mt={5} sx={{ Direction: "column", gap: "12px" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} />
                        <Typography variant="fm-p1" color='neutral.70' fontWeight={500}>
                            <Translations text="Select Service" />
                        </Typography>
                    </Box>

                    <Grid item container xs={12}>
                        {mainServices?.length > 0 && (
                            <>
                                {mainServices.map((service, index) => (
                                    <Grid key={index} item xs={12} md={12}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox
                                                checked={checkedItems[service.title] || false}
                                                onChange={(e) => handleCheckboxChange(e, service.title)}
                                            />
                                            <Typography variant="body1" color='neutral.90'>
                                                {service.title}
                                            </Typography>
                                        </Box>
                                        {checkedItems[service.title] && (
                                            <FormControl>
                                                <FormLabel htmlFor={`visits-${index}`}>
                                                    {translation.translate("Number of Visits")}
                                                </FormLabel>
                                                <TextField
                                                    type="tel"
                                                    min="0"
                                                    value={manualInputValues[service.title] || 0}
                                                    onChange={(e) => handleManualInputChange(e, service.title)}
                                                    id={`visits-${index}`}
                                                    required
                                                    sx={{
                                                        width: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'center',
                                                        }
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconButton
                                                                    aria-label="decrement"
                                                                    onClick={() => handleDecrement(service.title)}
                                                                >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="increment"
                                                                    onClick={() => handleIncrement(service.title)}
                                                                >
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        )}
                                    </Grid>
                                ))}
                            </>
                        )}
                    </Grid>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} />  <Typography variant="fm-p1" color='neutral.70' fontWeight={500}>
                            <Translations text="Select Packs" />
                        </Typography>
                    </Box>
                    <Grid item container spacing={6}>
                        {packs?.length && packs?.map((pack, index) => {
                            return (
                                <Grid item xs={12} md={6} key={index}>
                                    <Card sx={{ border: selectedValues.includes(`${pack.title}`) ? '2px solid #7338AC' : 'none' }}>
                                        <CardContent sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={selectedValues.includes(`${pack.title}`)} onChange={() => handleChange(`${pack.title}`)} />}
                                                label={
                                                    <Typography variant="fm-p1" fontWeight={500} color='neutral.80'>
                                                        {pack.title}
                                                    </Typography>
                                                }
                                            />
                                            {pack?.services?.length > 0 && (
                                                <>
                                                    {pack.services.map((service, index) => (
                                                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                            <CircleIcon sx={{ fontSize: '12px', color: theme.palette.primary.main }} />
                                                            <Typography variant="fm-p2" color="neutral.90" fontWeight={400}>
                                                                {service?.title}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Typography variant="fm-p2" color='neutral.70' fontWeight={400}>
                        {packsDescription}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default ByopPlan;
