import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'
import { Box, Button, Card, CardActionArea, CardContent, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, useTheme } from "@mui/material"
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useCustomTranslation from "src/@core/hooks/useCustomTranslation";
import Translations from "src/layouts/components/Translations";
import CongratulationsDialog from "./dialog/Congratulations";
import { Link } from "react-router-dom";

const validationSchema = yup.object().shape({})
function Payment() {
    const [selectedValue, setSelectedValue] = useState('Added card');
    const theme = useTheme()
    const translation = useCustomTranslation()
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const [isDialogOpen, setDialogOpen] = useState(false)

    const toggleDialog = (e) => {
        setDialogOpen(prev => !prev);
    }
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            profileImg: null,
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })

    return (
        <>
            <Box sx={{ width: '100%', position: 'relative', minHeight: '100%' }}>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="fm-p1" color='nuetral.80' fontWeight={400}>
                                    {translation.translate("Continue Your Payment With Card")}
                                </Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-form-control-label-placement"
                                    name="position"
                                    defaultValue="top"
                                    value={selectedValue}
                                    onChange={handleChange}
                                >
                                    <Grid container xs={12} spacing={6} mt={5} >
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{ border: selectedValue === 'Added card' ? '2px solid #7338AC' : 'none' }} >
                                                <CardActionArea onClick={() => setSelectedValue('Basic Plan')} >
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="Added card"
                                                            control={<Radio />}
                                                            label={
                                                                <Typography variant="fm-p2" color={selectedValue === 'Added card' ? 'neutral.80' : '#7E7585'}>
                                                                    {translation.translate("Added Card")}
                                                                </Typography>
                                                            }
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{ border: selectedValue === 'Add New Card' ? '2px solid #7338AC' : 'none' }}>
                                                <CardActionArea onClick={() => setSelectedValue('Add New Card')}>
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="Add New Card"
                                                            control={<Radio />}
                                                            label={
                                                                <Typography variant="fm-p2" color={selectedValue === 'Add New Card' ? 'neutral.80' : '#7E7585'}>
                                                                    {translation.translate("Add New Card")}
                                                                </Typography>
                                                            }
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    {selectedValue === 'Add New Card' && (<>
                                        <Grid item container spacing={6} mt={5}>
                                            <Grid item xs={12}>
                                                <Typography variant="fm-p1" color='nuetral.80' fontWeight={400}>{translation.translate("Fill up your cards")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth sx={{ mb: 4 }}>
                                                    <FormLabel htmlFor='nameOnCard'>{translation.translate("form.nameOnCard.label")}</FormLabel>
                                                    <Controller
                                                        name='nameOnCard'
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                autoFocus
                                                                id='nameOnCard'
                                                                placeholder={translation.translate("form.nameOnCard.placeholder")}
                                                            />
                                                        )}
                                                    />
                                                    {errors.nameOnCard && (
                                                        <FormHelperText sx={{ color: 'error.main' }}>
                                                            <Translations text={`form.nameOnCard.error.${errors.nameOnCard.message}`} />
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth sx={{ mb: 4 }}>
                                                    <FormLabel htmlFor='cardNumber'>{translation.translate("form.cardNumber.label")}</FormLabel>
                                                    <Controller
                                                        name='cardNumber'
                                                        control={control}
                                                        render={({ field: { value, onChange } }) =>
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                autoFocus
                                                                id='cardNumber'
                                                                placeholder={translation.translate("form.cardNumber.placeholder")}
                                                            />}
                                                    />
                                                    {errors.cardNumber && (
                                                        <FormHelperText sx={{ color: 'error.main' }}>
                                                            <Translations text={`form.cardNumber.error.${errors.cardNumber.message}`} />
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth sx={{ mb: 4 }}>
                                                    <FormLabel htmlFor='cvv'>{translation.translate("form.cvv.label")}</FormLabel>
                                                    <Controller
                                                        name='cvv'
                                                        control={control}
                                                        render={({ field: { value, onChange } }) =>
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                autoFocus
                                                                id='cvv'
                                                                placeholder={translation.translate("form.cvv.placeholder")}
                                                            />}
                                                    />
                                                    {errors.cvv && (
                                                        <FormHelperText sx={{ color: 'error.main' }}>
                                                            <Translations text={`form.cvv.error.${errors.cvv.message}`} />
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </>)}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Box sx={{ width: '100%', position: 'absolute', bottom: 0 }}>
                    <Grid item container xs={12} display='flex' justifyContent='space-between' alignItems='center' sx={{ background: '#FFF', p: '16px 24px', position: 'absolute', bottom: 0, width: '100%', zIndex: 1 }}>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography variant='fm-p1' color='netural.60' fontWeight={500}>
                                    <Translations text="Total Amount (Applied 10% discount for adding 5 or more than 5 members.)" />
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='fm-h5' color='netural.60' fontWeight={600}>
                                    $XXXX
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} display='flex' justifyContent={{ md: 'flex-end', xs: 'flex-start' }} gap={2}>
                            <Button sx={{ width: { xs: '100%', sm: '30%' }, padding: "12px", display: "flex", justifyContent: "center" }} variant='contained' onClick={toggleDialog}>
                                <Typography variant='fm-p2' fontWeight={600}>
                                    <Translations text="Complete Payment" />
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <CongratulationsDialog
                open={isDialogOpen}
                toggle={toggleDialog}
            />
        </>

    )
}
export default Payment