// ** Icon imports
import EarningIcon from 'src/@core/components/icons/EarningIcon'
import PortfolioIcon from 'src/@core/components/icons/PortfolioIcon'
import ProfileIcon from 'src/@core/components/icons/ProfileIcon'
import ProviderIcon from 'src/@core/components/icons/ProviderIcon'

const navigation = () => {
  return [
    {
      title: 'My Care Portfolio',
      icon: PortfolioIcon,
      path: '/portfolio'
    },
    {
      title: 'Providers',
      icon: ProviderIcon,
      path: '/portfolio/providers'
    },
    {
      title: 'My Earnings',
      icon: EarningIcon,
      path: '/my/earnings'
    },
    {
      title: 'My Profile',
      icon: ProfileIcon,
      path: '/my/profile'
    }
  ]
}

export default navigation
