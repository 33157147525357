import React, { Fragment, useCallback } from 'react';
import { Typography } from '@mui/material';
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';
import Close from 'mdi-material-ui/Close';
import { useDropzone } from 'react-dropzone';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import DropzoneWrapper from 'src/@core/styles/libs/react-dropzone';
import FileUploadIcon from 'src/@core/components/icons/FileUploadIcon';
import AddIcon from '@mui/icons-material/Add';

const MainWrapper = {
    minHeight: '190px',
    textAlign: 'center',
    border: '1.5px dashed #B1A8BA',
    borderRadius: '10px',
    stroke: '#5f4a8a',
    strokeWidth: '1px',
    flexShrink: '0',
};

function CustomDropzone({
    title = "Upload file",
    files,
    onChange,
    multiple = false,
    accept,
    height,
    width,
    maxSize = 1000 * 1000 * 3 // 3mb
}) {
    const onDrop = useCallback((acceptedFiles) => {
        if (multiple) {
            // Filter out existing string filenames before adding new files
            const filteredFiles = acceptedFiles.filter(file => typeof file !== 'string');
            onChange([...files, ...filteredFiles]);
        } else {
            if (typeof acceptedFiles[0] === 'string') {
                onChange(acceptedFiles[0]); // If string, directly set the string filename
            } else {
                onChange(acceptedFiles[0]); // Change to onChange([acceptedFiles[0]]) if you want an array always
            }
        }
    }, [files, multiple, onChange]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept,
        multiple: multiple,
        maxSize: maxSize,
    });

    const renderFilePreview = file => {
        if (file.type && file.type.startsWith('image')) {
            return <img width={38} height={38} alt={file.name} src={URL.createObjectURL(file)} />;
        } else {
            return <FileDocumentOutline />;
        }
    };

    const handleRemoveFile = file => {
        if (multiple) {
            onChange(files.filter(f => f !== file));
        } else {
            onChange(null);
        }
    };

    const truncateFileName = (fileName, maxLength = 10) => {
        if (fileName?.length <= maxLength) return fileName;
        return fileName?.slice(0, maxLength) + '...';
    };

    const fileItem = (file) => {
        if (typeof file === "string") return (
            <ListItem
                key={file}
                secondaryAction={
                    <IconButton edge="end" onClick={() => handleRemoveFile(file)}>
                        <Close fontSize='small' />
                    </IconButton>
                }
            >
                <ListItemAvatar>
                    <FileUploadIcon />
                </ListItemAvatar>
                <ListItemText
                    primary={<Typography className='file-name'>{truncateFileName(file)}</Typography>}
                />
            </ListItem>
        );



        return (
            <ListItem
                key={file.name}
                secondaryAction={
                    <IconButton edge="end" onClick={() => handleRemoveFile(file)}>
                        <Close fontSize='small' />
                    </IconButton>
                }
            >
                <ListItemAvatar>
                    {renderFilePreview(file)}
                </ListItemAvatar>
                <ListItemText
                    primary={<Typography className='file-name'>{truncateFileName(file.name)}</Typography>}
                    secondary={
                        <Typography className='file-size' variant='body2'>
                            {
                                Math.round(file.size / 100) / 10 > 1000
                                    ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
                                    : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`
                            }
                        </Typography>
                    }
                />
            </ListItem>
        );
    };

    const fileList = files ? (multiple ? files.map(file => (fileItem(file))) : fileItem(files)) : null;

    return (
        <DropzoneWrapper>
            <Fragment>
                <div {...getRootProps({ className: 'dropzone' })} style={{ ...MainWrapper, height, width }}>
                    <input {...getInputProps()} />
                    <div>
                        <AddIcon />
                        <Typography>{title}</Typography>
                    </div>
                </div>
                {files && (
                    <List>{fileList}</List>
                )}
            </Fragment>
        </DropzoneWrapper>
    );
}

export default CustomDropzone;
