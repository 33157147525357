// ** MUI Imports
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

// ** Components
// import Autocomplete from 'src/layouts/components/Autocomplete'
// import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
// import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
// import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import { TextField, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { logDOM } from '@testing-library/react'
import { useEffect, useRef } from 'react'
import { Trans, Translation } from 'react-i18next'
import Translations from '../Translations'

const AppBarContent = props => {
  // ** Props
  const {
    hidden,
    settings,
    // saveSettings,
    toggleNavVisibility
  } = props
  const location = useLocation()
  const navigate = useNavigate()


  const goBack = () => {
    navigate(-1)
  }

  const openSearchPage = () => {
    navigate('/search')
  }
  useEffect(() => {
    // if (.includes('/portfolio/member-detail/')) {
    //   pathRef(location.pathname)
    // }
  }, [])

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
        {location.pathname === '/portfolio' && (
          <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
            <Translations text="My Care Portfolio" />
          </Typography>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          {location.pathname !== '/portfolio' && location.pathname !== '/my/profile' && location.pathname !== '/my/earnings' && location.pathname !== '/providers' && (
            <Box>
              <IconButton sx={{
                backgroundColor: '#7338AC',
                '&:hover': {
                  backgroundColor: '#582a84', // Darker shade of the default color
                },
              }} onClick={goBack}>
                <ArrowBackIosNewRoundedIcon fontSize='small' sx={{ color: '#ffffff' }} />
              </IconButton>
            </Box>
          )}
          <Box>
            {location.pathname === '/providers' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Providers" />
              </Typography>
            )}
            {location.pathname === '/my/profile' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="My Profile" />
              </Typography>
            )}
            {location.pathname === '/my/earnings' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="My Earnings" />
              </Typography>
            )}
            {location.pathname === '/portfolio/add-member' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Add Member" />
              </Typography>
            )}
            {location.pathname.includes('/portfolio/member-detail') && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Member Details" />
              </Typography>
            )}
            {location.pathname.includes('/portfolio/edit-member/') && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Edit Member" />
              </Typography>
            )}
            {location.pathname === '/portfolio/update-plan' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Update Coverage Plan" />
              </Typography>
            )}
            {location.pathname.includes('/providers/provider-details/') && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Providers Details" />
              </Typography>
            )}
            {location.pathname === '/my/profile/change-password' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Change Password" />
              </Typography>
            )}
            {location.pathname.includes('/portfolio/general-practitionerlist') && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Select Provider" />
              </Typography>
            )}
            {location.pathname === '/portfolio/make-payment' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Make Payment" />
              </Typography>
            )}
            {location.pathname === '/portfolio/payment' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Complete Payment" />
              </Typography>
            )}
            {location.pathname.includes('/portfolio/general-practitioner-detail') && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Providers Details" />
              </Typography>
            )}
            {location.pathname === '/portfolio/added-member-list' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Select Plan" />
              </Typography>
            )}
            {location.pathname.includes('/portfolio/added-member-list/plans/') && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Select Plan" />
              </Typography>
            )}
            {location.pathname === '/search' && (
              <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                <Translations text="Search" />
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <MenuIcon />
          </IconButton>
        ) : null}
        {/* <Autocomplete hidden={hidden} settings={settings} /> */}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
        {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}

        <Box>
          <TextField
            type='search'
            size='small'
            // value={search}
            disabled
            placeholder='Search here'
            // onChange={e => setSearch(e.target.value)}
            // onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton>
                    <img
                      src='/assets/images/search-normal.png'
                      alt='Search'
                      style={{ width: '24px', height: '24px' }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
            onClick={openSearchPage}
          />
        </Box>
        <NotificationDropdown settings={settings} />
        {/* <UserDropdown settings={settings} /> */}
      </Box>
    </Box>
  )
}

export default AppBarContent
