import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'

function MembershipCardDialog({ open, toggle }) {
  const translation = useCustomTranslation()

  const handleToggle = () => {
    toggle()
  }

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} onClose={toggle} PaperProps={{ sx: { borderRadius: '24px' } }}>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          py: '20px',
          px: '15px'
          //   textAlign: 'center'
        }}
      >
        <DialogTitle variant='h6' fontWeight={600}>
          {translation.translate(`MembershipCardDialog.MembershipCard`)}
        </DialogTitle>

        <DialogContent>
          <img src='/assets/images/memberDetails/membercard.svg' alt='memberCard'></img>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Link style={{ textDecoration: 'none' }}>
            <Button onClick={() => handleToggle()} variant='contained' sx={{ padding: '12px 30px 12px 30px' }}>
              <Typography variant='fm-p3' fontWeight={600}>
                {translation.translate(`MembershipCardDialog.DownloadMembershipCard`)}
              </Typography>
            </Button>
          </Link>
        </DialogActions>
      </Card>
    </Dialog>
  )
}

export default MembershipCardDialog
