import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'
import AddMember from './pages/addMember'
import AddMemberForm from './pages/addMember'
import GeneralPractitionerList from './views/GeneralPractitionerList'
import GeneralPractitionerDetail from './views/GeneralPractitionerDetail'
import MakePayment from './views/MakePayment'
import Payment from './views/payment'
import ChangeLanguage from './pages/changeLanguage'
import MembershipCardDialog from './views/dialog/membershipCard'


const HomePage = React.lazy(() => import('./pages/home'))
const LoginPage = React.lazy(() => import('./pages/login'))
const RegisterPage = React.lazy(() => import('./pages/register'))
const ChangePasswordPage = React.lazy(() => import('./pages/changePassword'))
const ProfilePage = React.lazy(() => import('./pages/profile'))
const MyEarningsPage = React.lazy(() => import('./pages/myEarnings'))
const ProviderPage = React.lazy(() => import('./pages/providers'))
const ProviderDetailsPage = React.lazy(() => import('./pages/providers/providerDetails'))
const MemberDetailsPage = React.lazy(() => import('./pages/memberDetails/index'))
const ForgotPasswordPage = React.lazy(() => import('./pages/forgotPassowrd'))
const VerifyOTPPage = React.lazy(() => import('./pages/verifyOTP'))
const ResetPasswordPage = React.lazy(() => import('./pages/resetPassword'))
const Search = React.lazy(() => import('./pages/search'))
const AddedMemberList = React.lazy(() => import('./pages/addMember/addedMemberList'))
const PlanPage = React.lazy(() => import('./pages/addMember/plan'))
const EditMemberPage = React.lazy(() => import('./pages/editMember/'))

const PyamentSuccessPage = React.lazy(() => import('./pages/paymentSuccess'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route element={<BlankLayout><Outlet /></BlankLayout>}>
            <Route path='/401' element={<Page401 />} />
            <Route path='/404' element={<Page404 />} />
            <Route path='/payment-success' element={<PyamentSuccessPage />} />

            <Route element={<AuthLayout><Outlet /></AuthLayout>}>
              <Route element={<Guard guestGuard><Outlet /></Guard>}>
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/register' element={<RegisterPage />}></Route>
                <Route path='/forgot-password' element={<ForgotPasswordPage />} />
                <Route path='/verify-otp' element={<VerifyOTPPage />} />
                <Route path='/reset-password' element={<ResetPasswordPage />} />
              </Route>
            </Route>
          </Route>

          <Route path='/' element={<Navigate to='/portfolio' replace />} />

          <Route element={<UserLayout><Outlet /></UserLayout>}>
            <Route element={<Guard authGuard><Outlet /></Guard>}>
              <Route path='/portfolio' element={<HomePage />} />
              <Route path='/portfolio/member-detail/:id' element={<MemberDetailsPage />} />
              <Route path='/portfolio/membership-card' element={<MembershipCardDialog />} />
              <Route path='/portfolio/add-member' element={<AddMemberForm />} />

              <Route path='/portfolio/added-member-list' element={<AddedMemberList />} />
              <Route path='/portfolio/added-member-list/plans/:id' element={<PlanPage />} />

              <Route path='/portfolio/edit-member/:id' element={<EditMemberPage />} />

              <Route path='/portfolio/update-plan' element={<AddMemberForm mode="updatePlan" />} />
              <Route path='/portfolio/general-practitionerlist/:id' element={<GeneralPractitionerList />} />
              <Route path='/portfolio/general-practitioner-detail/:id' element={<GeneralPractitionerDetail />} />
              <Route path='/portfolio/make-payment' element={<MakePayment />} />
              <Route path='/portfolio/payment' element={<Payment />} />

              <Route path='/providers' element={<ProviderPage />} />
              <Route path='/providers/provider-details/:id' element={<ProviderDetailsPage />} />

              <Route path='/my/earnings' element={<MyEarningsPage />} />
              <Route path='/my/profile' element={<ProfilePage />} />
              <Route path='/my/profile/change-password' element={<ChangePasswordPage />} />

              <Route path='/changeLanguage' element={<ChangeLanguage />} />
              <Route path='/search' element={<Search />} />
            </Route>
          </Route>

          {/* If no route found redirect it to --> /404 */}
          <Route path='*' element={<Navigate to='/404' replace />} />

        </Routes>
      </AclGuard>
    </Suspense>
  );
}

export default App;
