import { Box, Card, MenuItem, CardContent, Grid, FormLabel, Typography, Select, styled } from '@mui/material'
import {
    FormControl,
    FormControlLabel,
    Button,
} from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import Translations from 'src/layouts/components/Translations'
import { useState } from 'react'
import { FormValidationMessages } from 'src/constants/form.const'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const validationSchema = yup.object().shape({
    firstName: yup.string().required(FormValidationMessages.FIRST_NAME.required),
})
const CustomButton = styled(Button)(({ theme }) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
    padding: '12px 24px',
    fontWeight: 600,
   marginTop:'28px'
}))
function ChangeLanguage() {
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            profileImg: null,
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Card>
                        <form noValidate onSubmit={handleSubmit()} className='auth__form'>
                            <CardContent>
                                {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>                                   
                                    <FormControl >
                                        <FormLabel htmlFor='demo-simple-select-label'>Select Your Language</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            //value={selectlanguage}
                                            //onChange={handlelanguageChange}
                                            IconComponent={() => <ExpandMoreIcon style={{ fontSize: "35px" }} />}
                                        >
                                            <MenuItem value={'english'}><Typography variant='fm-p2' color='#7338AC'>English</Typography></MenuItem>
                                            <MenuItem value={'gujarati'}><Typography variant='fm-p2' color='#7338AC'>Gujarati</Typography></MenuItem>
                                        </Select>
                                    </FormControl>                                 
                                    <CustomButton type='submit' variant="contained"><Translations text="Save Changes" /></CustomButton>                                   
                                </Box> */}

                                <Grid item container spacing={3}>
                                    <Grid item xs={12} md={10}>
                                        <FormControl fullWidth >
                                            <FormLabel htmlFor='demo-simple-select-label'>Select Your Language</FormLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                //value={selectlanguage}
                                                //onChange={handlelanguageChange}
                                                IconComponent={() => <ExpandMoreIcon style={{ fontSize: "35px" }} />}
                                            >
                                                <MenuItem value={'english'}><Typography variant='fm-p2' color='#7338AC'>English</Typography></MenuItem>
                                                <MenuItem value={'gujarati'}><Typography variant='fm-p2' color='#7338AC'>Gujarati</Typography></MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <CustomButton type='submit' variant="contained"><Translations text="Save Changes" /></CustomButton>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </form>
                    </Card>
                </Grid >
            </Grid >

        </>
    )
}
export default ChangeLanguage
