import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Checkbox
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function SelectDialog({ open, toggle }) {
  const handlePayment = () => {
    toggle()
  }

  const data = [
    { name: 'Alena Smith', description: '#FML1000M'  , img : '/assets/images/person.png'},
    { name: 'Alena Smith', description: '#FML1000M' ,img : '/assets/images/person.png' }
  ]
  return (
    <>
      <Dialog open={open} onClose={toggle} PaperProps={{ sx: { borderRadius: '12px' } }}>
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            py: '20px',
            px: '15px',
            textAlign: 'center'
          }}
        >
          <DialogTitle variant='fm-h6' fontWeight={600}>
            {' '}
            Select Member
          </DialogTitle>
          <DialogContent variant='fm-p3' fontWeight={400}>
            Kindly choose for which member you want to
            {/* <br /> */}
            select this practioner.
          </DialogContent>
          {data.map((item, index) => (
            <Card sx={{ backgroundColor: '#F1EBF7', width: '100%' , my:2 }}>
              <CardContent>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      gap: '16px'
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '20px'
                      }}
                    >
                      <img
                        style={{ maxWidth: '100px', height: '100%', borderRadius: '10px' }}
                        src= {item.img}
                        alt='profile'
                      ></img>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                      <Typography variant='fm-p2' color='neutral.80' fontWeight={600}> {item.name}</Typography>
                      <Typography variant='fm-p2' color='#826C99' fontWeight={600}>{item.description}</Typography>
                    </Box>
                  </Grid>

                  <Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Checkbox disableRipple />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}

          <DialogActions sx={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
            <Button onClick={toggle} sx={{ padding: '12px', width: '100%', backgroundColor: '#EBF3F7' }}>
              <Typography variant='fm-p2'>Cancel</Typography>
            </Button>
            <Link to='/portfolio/make-payment' style={{ textDecoration: 'none', width: '100%' }}>
              <Button onClick={() => handlePayment()} variant='contained' sx={{ padding: '12px', width: '100%' }}>
                <Typography variant='fm-p2'>Submit</Typography>
              </Button>
            </Link>
          </DialogActions>
        </Card>
      </Dialog>
    </>
  )
}
export default SelectDialog
