import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useCustomTranslation from "src/@core/hooks/useCustomTranslation";


const MemberConfirmDialog = ({ open, toggle, handleConfirm, handleCancel }) => {
    const translation = useCustomTranslation();

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={open}
                onClose={toggle}
                PaperProps={{ sx: { borderRadius: '24px' } }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        py: '20px',
                        px: '15px',
                        textAlign: 'center',
                    }}
                >
                    <DialogTitle variant="fm-h6" fontWeight={600}>
                        {translation.translate("Confirm Resubmission")}!
                    </DialogTitle>
                    <DialogContent variant="fm-p3" fontWeight={400}>
                        {translation.translate("Are you sure you want to return to the previous member data? This will cause you to lose any unsaved data in the current form.")}.
                    </DialogContent>

                    <DialogActions sx={{ display: "flex", gap: "10px", width: "100%" }}>
                        <Button onClick={handleCancel} sx={{ backgroundColor: '#EBF3F7', color: '#0B1B22', padding: "12px", width: "100%" }}>
                            <Typography variant="fm-p2">{translation.translate("Cancel")}</Typography>
                        </Button>

                        <Button onClick={handleConfirm}
                            variant="contained" sx={{ padding: "12px", width: "100%" }}>
                            <Typography variant="fm-p2">{translation.translate("Confirm")}</Typography>
                        </Button>
                    </DialogActions>
                </Card>
            </Dialog>
        </>
    );
};

export default MemberConfirmDialog;