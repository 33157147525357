export const FormValidationMessages = {
    EMAIL: {
        required: 'Email is required',
        patternRequired: 'Invalid email format',
        invalid: 'Must be a valid email address',
        max: 'Email cannot be more than 50 characters'
    },
    PASSWORD: {
        required: 'Password is required',
        minLength: 8,
        minLengthErrorMessage: 'Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase',
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        patternErrorMessage: 'Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase'
    },
    FIRST_NAME: {
        required: 'First name is required',
        maxErrorMessage: 'First name cannot be more than 20 characters'
    },
    MIDDLE_NAME: {
        required: 'Middle name is required',
        maxErrorMessage: 'Middle name cannot be more than 20 characters'
    },
    LAST_NAME: {
        required: 'Last name is required',
        maxErrorMessage: 'Last name cannot be more than 50 characters'
    },
    BUSINESS_NAME: {
        required: 'Name of Business is required',
    },
    CITY_NAME: {
        required: 'City Name is required',
    },
    STATE: {
        required: 'State is required',
    },
    COUNTRY: {
        required: 'Country is required',
    },
    DOB: {
        required: 'Date of Birth is required',
        minDateErrorMessage: 'Must be 18 years or older'
    },
    IS_USER_CONSENT: {
        required: 'User Consent is required',
    },
    PHONE: {
        required: 'Phone number is required',
        pattern: /^\d{10}$/,
        patternErrorMessage: 'Must contain only numeric digits and only 10 digits',
        matchMessage: "Phone number must be exactly 10 digits"
    },
    isPreExistingCondition: {
        required: 'required'
    },
    NAME_ON_CARD: {
        required: 'Name on card is required',
    },
    CARD_NUMBER: {
        required: 'Card number is required',
    },
    CVV: {
        required: 'Cvv is required',
    },
    VISITS: {
        required: 'Visits is required',
    }
}