import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useCustomTranslation from "src/@core/hooks/useCustomTranslation";

const ProviderConfirmDialog = ({ toggle, open, handleConfirm }) => {
    const translation = useCustomTranslation()
    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={open}
                onClose={toggle}
                PaperProps={{ sx: { borderRadius: '24px' } }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        py: '20px',
                        px: '15px',
                        textAlign: 'center',
                    }}
                >
                    <DialogTitle variant="fm-h6" fontWeight={600}>{translation.translate("Are You Sure?")}!</DialogTitle>
                    <DialogContent variant="fm-p3" fontWeight={400}>
                        {translation.translate("Are you sure you want to continue with this provider?")}
                    </DialogContent>

                    <DialogActions sx={{ display: "flex", flexDirection: 'column', gap: "10px", width: "100%" }} >

                        <Button onClick={toggle} sx={{ backgroundColor: '#EBF3F7', color: '#0B1B22', padding: "12px", width: "100%" }}>
                            <Typography variant="fm-p2">{translation.translate("Cancel")}</Typography>
                        </Button>


                        <Button onClick={() => {
                            handleConfirm()
                            toggle();
                        }}
                            variant="contained" sx={{ padding: "12px", width: "100%" }}>
                            <Typography variant="fm-p2">{translation.translate("Okay")}</Typography>
                        </Button>

                    </DialogActions>
                </Card>
            </Dialog>
        </>
    )
}

export default ProviderConfirmDialog