import { Box, Button, Card, CardActionArea, CardActions, CardContent, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles'
import ByopPlan from "./component/Byop";
import useCustomTranslation from "src/@core/hooks/useCustomTranslation";
import { axiosInstance } from "src/network/adapter";
import { ApiEndpoints } from "src/network/endpoints";
import toast from 'react-hot-toast'
import Loader from "src/@core/components/loader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Translations from "src/layouts/components/Translations";
import CommonPlan from "./component/CommonPlan";

function CoveragePlan() {


    const { id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const date = searchParams.get('date');
    const plan = searchParams.get('plan');
    const isAlready = searchParams.get('isAlready');
    const status = searchParams.get('status');

    const navigate = useNavigate()

    const [age, setAge] = useState('')
    const [year, setYear] = useState('monthly')

    const [errors, setErrors] = useState({});

    const handleYearChange = event => {
        setYear(event.target.value)
    }


    useEffect(() => {
        const calculateAgeDifference = (selectedDate) => {
            if (selectedDate) {
                const dobDate = new Date(selectedDate);
                const today = new Date();
                const ageDifferenceInYears = today.getFullYear() - dobDate.getFullYear();
                if (ageDifferenceInYears < 18) {

                    setAge('0-18');
                } else if (ageDifferenceInYears >= 18 && ageDifferenceInYears <= 44) {
                    setAge('18-44');
                } else {
                    setAge('45+');
                }
            }
        };

        calculateAgeDifference(date);
    }, [date]);

    const theme = useTheme()
    const translation = useCustomTranslation()
    const [selectedValue, setSelectedValue] = useState('');
    const [loading, setLoading] = useState(false)
    const [allPlans, setAllPlans] = useState([])


    const [PlanDetails, setPlanDetails] = useState([])
    const [selectedPlanDetails, setSelectedPlanDetails] = useState(null)

    const getPlans = (age) => {
        setLoading(true);
        axiosInstance
            .get(ApiEndpoints.MEMBER.plans, { params: { age: age } })
            .then((response) => {
                const data = response?.data?.data;
                setAllPlans(data?.plans)
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getSelectedPlans = (type) => {
        setLoading(true);
        axiosInstance
            .get(ApiEndpoints.MEMBER.plans, { params: { search: type, age: age } })
            .then((response) => {
                const data = response?.data?.data;
                console.log("PLAN DETAILS",data?.plans)
                setPlanDetails(data?.plans)
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (age)
            getPlans(age);
    }, [age])


    const handleRadioChange = (event) => {
        const { value } = event.target;
        setSelectedValue(value);
        getSelectedPlans(value)
    };
    
    const findCostPerUnit = (selectedPackage, ServicePackage) => {
        if (selectedPackage != null) {
            for (const planPackage of selectedPackage) {
                for (const item of ServicePackage?.packages_item) {
                    if (planPackage.package_name === item.title) {
                        return item.cost_per_unit;
                    }
                }
            }
        }
        return 0;
    };


    const handleSubmit = async () => {
        let payload;
        const totalAmount = selectedPlanDetails?.extraService?.reduce((total, service) => {
            return total + service.cost_per_unit_usd;
        }, 0);

        const totalByopAmount = selectedPlanDetails?.mainService?.reduce((total, service) => {
            return total + service?.cost_per_unit_usd * service?.visit;
        }, 0);

        const costPerUnit = findCostPerUnit(selectedPlanDetails?.extraService, PlanDetails[0]?.packages);

        if (selectedValue === "BYOP Plan") {
            payload = { // Removed `let` to avoid re-declaration
                plan_type: selectedValue,
                plan_duration_type: year,
                age_range: age,
                annual_amount: year==="monthly" ? PlanDetails[0]?.monthly_premium : PlanDetails[0]?.yearly_premium,
                annual_membership_amount: PlanDetails[0]?.annual_membership_fee,
                plan_details: selectedPlanDetails?.bulletPlan,
                // packages: selectedPlanDetails?.extraService,
                total_amount: totalByopAmount + costPerUnit + PlanDetails[0]?.annual_membership_fee + PlanDetails[0]?.monthly_premium,
                extra_services:[]
            };

            // Check if selectedPlanDetails.mainService is an array and not null or undefined
            if (Array.isArray(selectedPlanDetails?.mainService)) {
                const servicesToInclude = selectedPlanDetails.mainService.filter(service => service.is_visit && service.visit > 0);
                if (servicesToInclude.length > 0) {
                    payload.services = servicesToInclude;
                }
            }

            if (selectedPlanDetails?.extraService?.length > 0) {
                payload.packages = selectedPlanDetails?.extraService;
            }
        } else {

            let annualAmount = year ==="monthly" ? PlanDetails[0]?.age_group[0]?.monthly_premium : PlanDetails[0]?.age_group[0]?.monthly_premium * 12

            payload = {
                plan_type: selectedValue,
                plan_duration_type: year,
                age_range: age,
                annual_amount : annualAmount,
                annual_membership_amount: PlanDetails[0]?.age_group[0]?.annual_membership_fee,
                total_amount: totalAmount + annualAmount + PlanDetails[0]?.age_group[0]?.annual_membership_fee,
                plan_details: selectedPlanDetails?.bulletPlan,
                services: selectedPlanDetails?.mainService,
                ...(selectedPlanDetails.extraService.some(service => service.is_visit && service.visit > 0)
                    ? { extra_services: selectedPlanDetails.extraService.filter(service => service.is_visit && service.visit > 0) }
                    : {})
            };
        }
        console.log('PLAN PAYLOAD',payload)
        try {
            const response = await axiosInstance.patch(`${ApiEndpoints.MEMBER.createPlan}${id}/coverage-plan`, payload)
            const responseData = response.data
            toast.success(responseData?.message)
            navigate('/portfolio/added-member-list')
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        if (plan) {
            setSelectedValue(plan)
            getSelectedPlans(plan)
        } else {
            setSelectedValue('')
        }
    }, [plan])

    return (
        <>
            <Card>
                <CardContent sx={{ margin: '15px' }}>
                    <Grid item xs={12}>
                        <Typography variant='fm-h6' fontWeight={600}>
                            <Translations text="Interested Coverage Plan" />
                        </Typography>
                    </Grid>
                    <Card sx={{ background: '#F5F4F9', borderRadius: '12px', mt: 5 }}>
                        <CardContent>
                            <Grid item container mt={'3px'} xs={12}>
                                <Grid item xs={12} md={6} sm={6}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                        <Typography variant='fm-h6' color='neutral.80' fontWeight={500}>
                                            <Translations text='Change Plan Payment Frequency' />
                                        </Typography>
                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={400}>
                                            <Translations text='For each coverage option selected, we display different details and different cost.' />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} sm={12} sx={{ display: 'flex', justifyContent: { md: 'flex-end', xs: 'flex-start', gap: '5px' } }}>
                                    <Box>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                value={age} // Set value based on calculated age
                                                onChange={(event) => setAge(event.target.value)} // Update age based on dropdown selection
                                                // IconComponent={() => <ExpandMoreIcon style={{ fontSize: '35px' }} />}
                                                disabled
                                                sx={{ display: 'none' }}
                                            >
                                                <MenuItem value='0-18'>
                                                    <Typography variant='fm-p2' color='#7338AC'>
                                                        {translation.translate('< 18yo')}
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem value='18-44'>
                                                    <Typography variant='fm-p2' color='#7338AC'>
                                                        {translation.translate('18-44 yo')}
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem value='45+'>
                                                    <Typography variant='fm-p2' color='#7338AC'>
                                                        {translation.translate('> 45yo')}
                                                    </Typography>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                value={year}
                                                onChange={handleYearChange} // Update year based on dropdown selection

                                                sx={{ background: '#7338AC', color: '#ffffff', '& .MuiSelect-icon': { color: '#ffffff' } }}
                                            >
                                                <MenuItem value='monthly' sx={{ color: '#7338AC' }}>
                                                    <Typography variant='fm-p2'>{translation.translate('Monthly')}</Typography>
                                                </MenuItem>
                                                <MenuItem value='yearly' sx={{ color: '#7338AC' }}>
                                                    <Typography variant='fm-p2'>{translation.translate('Yearly')}</Typography>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* <CoveragePlan year={year} age={age} triggerSubmit={onSubmit} /> */}

                            {loading ? <Loader height="auto" /> :

                                <FormControl fullWidth>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-form-control-label-placement"
                                        name="position"
                                        defaultValue="top"
                                        value={selectedValue}
                                        onChange={handleRadioChange}
                                    >
                                        <Grid container xs={12} spacing={6} mt={5} >
                                            {allPlans?.length > 0 && (
                                                <>
                                                    {allPlans.map((plan, index) => {
                                                        return (
                                                            <>
                                                                <Grid item xs={12} sm={6} md={4} key={plan.title}>
                                                                    <Card sx={{ border: selectedValue === plan?.title ? '2px solid #7338AC' : 'none' }} key={plan.title}>
                                                                        <CardActionArea onClick={(e) => !isAlready && setSelectedValue(e.target.value)} key={plan.title}>
                                                                            <CardContent sx={{ backgroundImage: `url('/assets/images/logo.png')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', display: "flex", flexDirection: "column" }}>
                                                                                <FormControlLabel
                                                                                    key={plan.title}
                                                                                    value={plan?.title}
                                                                                    control={<Radio />}
                                                                                    label={
                                                                                        <Typography variant="fm-p2" color={selectedValue === plan?.title ? 'neutral.80' : '#7E7585'}>
                                                                                            {plan.title}
                                                                                        </Typography>
                                                                                    }
                                                                                    disabled={isAlready && status!=="waiting" ? true : false}
                                                                                />
                                                                                {year === 'monthly' ?
                                                                                    <Typography variant="fm-h3" color='neutral.80' key={plan.title} fontWeight={500} >
                                                                                        {plan?.title === "BYOP Plan" ? `$${plan?.monthly_premium}` : `$${plan?.age_group[0]?.monthly_premium}`} <Typography key={plan.title} variant="fm-h6" color='neutral.60'>{translation.translate("/Month")}</Typography></Typography>
                                                                                    :
                                                                                    <Typography variant="fm-h3" key={plan.title} color='neutral.80' fontWeight={500} >
                                                                                        {plan?.title === "BYOP Plan" ? `$${plan?.yearly_premium}` : `$${plan?.age_group[0]?.monthly_premium * 12}`} <Typography key={plan.title} variant="fm-h6" color='neutral.60'>{translation.translate("/Year")}</Typography></Typography>
                                                                                }

                                                                                <Typography variant="fm-p2" key={plan.title} color='theme.palette.text.light' fontWeight={500}>
                                                                                    Annual Membership: <Typography key={plan.title} variant="fm-h6" color={theme.palette.primary.main}>
                                                                                        {/* ${plan?.annual_membership_fee} */}
                                                                                        {plan?.title === "BYOP Plan" ? `$${plan?.annual_membership_fee}` : `$${plan?.age_group[0]?.annual_membership_fee}`}
                                                                                    </Typography></Typography>
                                                                            </CardContent>
                                                                        </CardActionArea>
                                                                    </Card>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </Grid>
                                    </RadioGroup>
                                    {/* {selectedValue === 'Basic Plan' && <BasicPlan key={selectedValue} serviceList={PlanDetails} setSelectedPlanDetails={setSelectedPlanDetails} />}
                                    {selectedValue === 'Specialist Plan' && <SpecialistPlan key={selectedValue} spacialServiceList={PlanDetails} />} */}
                                    {selectedValue === 'BYOP Plan' ? (
                                        <ByopPlan key={selectedValue} packages={PlanDetails} setSelectedPlanDetails={setSelectedPlanDetails} errors={errors} setErrors={setErrors} />
                                    ) : (
                                        PlanDetails?.length > 0 && (
                                            <CommonPlan
                                                key={selectedValue}
                                                serviceList={PlanDetails}
                                                setSelectedPlanDetails={setSelectedPlanDetails}
                                            />
                                        )
                                    )}

                                </FormControl>
                            }

                        </CardContent>
                    </Card>
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Button as={Link} to={`/portfolio/added-member-list`} variant='outlined' sx={{ textDecoration: 'none', width: '165px', padding: '12px 24px' }}>
                            <Typography variant="fm-p2" fontWeight={600}><Translations text="Cancel" /></Typography>
                        </Button>
                    </Box>
                    <Box>
                        <Button variant="contained" onClick={handleSubmit} sx={{ width: '165px', padding: '12px 24px' }}>
                            <Typography variant="fm-p2" fontWeight={600}><Translations text="Submit" /></Typography>
                        </Button>
                    </Box>
                </CardActions>
            </Card>


        </>
    )
}
export default CoveragePlan