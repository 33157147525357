import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useCustomTranslation from "src/@core/hooks/useCustomTranslation";

function CongratulationsDialog({ open, toggle }) {
    const translation = useCustomTranslation()
    const handleToggle = () => {
        toggle()
    }
    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={open}
                onClose={toggle}
                PaperProps={{ sx: { borderRadius: '24px' } }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        py: '20px',
                        px: '15px',
                        textAlign: 'center',
                    }}
                >
                    <DialogTitle variant="fm-h6" fontWeight={600}>{translation.translate("Congratulations")}!</DialogTitle>
                    <DialogContent variant="fm-p3" fontWeight={400}>
                        {translation.translate("You have successfully secured the health of your family. Their coverage will begin after a 6 months mandatory wait period, that is on xx/xx/xx (enter exact day)")}.
                    </DialogContent>

                    <DialogActions sx={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }} >
                        <Link to='/portfolio' style={{ textDecoration: "none", width: "100%" }}>
                            <Button onClick={() => handleToggle()} variant="contained" sx={{ padding: "12px", width: "100%" }}>
                                <Typography variant="fm-p2">{translation.translate("Continue")}</Typography>
                            </Button>
                        </Link>
                    </DialogActions>
                </Card>
            </Dialog>
        </>
    )
}
export default CongratulationsDialog