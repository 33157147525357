import { Autocomplete, Avatar, Box, Button, Card, CardContent, CircularProgress, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Translations from "src/layouts/components/Translations";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectDialog from "./dialog/SelectDialog";
import { useEffect, useState } from "react";
import { axiosInstance } from "src/network/adapter";
import { ApiEndpoints } from "src/network/endpoints";
import toast from "react-hot-toast";
import ProviderConfirmDialog from "./dialog/providerConfirmDialog";
import Loader from "src/@core/components/loader";

const GeneralPractitionerList = ({ data }) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const planValue = queryParams.get('plan');
    const { path } = location.state || {};

    const [isSelectDialogOpen, setSelectDialogOpen] = useState(false);
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [providerList, setProviderList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [statesList, setStatesList] = useState([]);
    const [countryLoading, setCountryLoading] = useState(false);
    const [statesLoading, setStatesLoading] = useState(false);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [memberProviderData, setMemberProviderData] = useState([]);
    const providerDetailsSelected = JSON.parse(localStorage.getItem('providerDetailsSelected')) || [];


    const toggleSelectDialog = (e) => {
        setSelectDialogOpen(prev => !prev);
    }
    const toggleConfirmDialog = (e) => {
        setConfirmDialogOpen(prev => !prev);
    }

    useEffect(() => {
        if (id)
            getMemberDetailsById(id);
    }, [id]);

    const getMemberDetailsById = (memberId) => {
        setLoading(true);
        axiosInstance
            .get(ApiEndpoints.MEMBER.getMyCartListById(memberId))
            .then((response) => {
                setMemberProviderData(response?.data?.data?.member?.provider);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // const getAllProviders = (plan) => {
    //     let params = {
    //         plan: plan
    //     }
    //     setLoading(true);
    //     axiosInstance
    //         .get(ApiEndpoints.PROVIDERS.getAllProviders, { params })
    //         .then((response) => {
    //             let list = response?.data?.data?.providers;
    //             setProviderList(list);

    //             // Check and mark already selected providers
    //             if (memberProviderData?.length > 0) {
    //                 const selected = list.filter(provider =>
    //                     memberProviderData.some(selectedProvider => selectedProvider.provider_id === provider._id)
    //                 );
    //                 setSelectedProviders(selected);
    //             }
    //         })
    //         .catch((error) => {
    //             toast.error(error?.response?.data?.message);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };


    const getAllProviders = (plan) => {
        let params = {
            plan: plan
        };
        setLoading(true);
        axiosInstance
            .get(ApiEndpoints.PROVIDERS.getAllProviders, { params })
            .then((response) => {
                let list = response?.data?.data?.providers;
                setProviderList(list);

                // Check and mark already selected providers from memberProviderData
                let selected = [];
                if (memberProviderData?.length > 0) {
                    selected = list.filter(provider =>
                        memberProviderData.some(selectedProvider => selectedProvider.provider_id === provider._id)
                    );
                }

                // Check if the providers in providerSelectedList exist in the list of providers
                const providerSelectedFromList = list.filter(provider =>
                    providerDetailsSelected.includes(provider._id)
                );

                // Combine both selected providers from memberProviderData and providerSelectedList
                selected = [...selected, ...providerSelectedFromList];

                // Remove duplicates if any (optional)
                selected = selected.filter((value, index, self) =>
                    index === self.findIndex((t) => t._id === value._id)
                );

                setSelectedProviders(selected);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const getCountryList = () => {
        setCountryLoading(true);
        axiosInstance
            .get(`${ApiEndpoints.GET_REGION.country}`, {
                headers: {
                    'X-CSCAPI-KEY': 'RmYyOHVGMnBSS0VoRUc5cTRMMmhnc2UwdE1WdVZqaHRicnhMbzJ6eA==' // Replace 'API_KEY' with your actual API key
                }
            })
            .then((response) => response?.data)
            .then((response) => {
                setCountryList(response);
            })
            .catch((error) => {
                toast.error(error?.response);
            })
            .finally(() => {
                setCountryLoading(false);
            });
    };

    useEffect(() => {
        getAllProviders(planValue);
        getCountryList();
    }, [planValue, memberProviderData]);

    const handleCountryChange = (event, value) => {
        if (value) {
            getStateList(value?.iso2);
        }
    };

    const getStateList = (val) => {
        setStatesLoading(true);
        axiosInstance
            .get(`${ApiEndpoints.GET_REGION.state}${val}/states`, {
                headers: {
                    'X-CSCAPI-KEY': 'RmYyOHVGMnBSS0VoRUc5cTRMMmhnc2UwdE1WdVZqaHRicnhMbzJ6eA=='
                }
            })
            .then((response) => response.data)
            .then((response) => {
                setStatesList(response);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
            .finally(() => {
                setStatesLoading(false);
            });
    }

    const handleProviderSelection = (provider) => {
        setSelectedProviders((prevSelected) => {
            if (prevSelected.includes(provider)) {
                return prevSelected.filter((item) => item !== provider);
            } else {
                return [...prevSelected, provider];
            }
        });
    };

    const transformData = (providers) => {
        const transformedProviders = providers.map(provider => {
            const fullName = [provider.first_name, provider.middle_name, provider.last_name].filter(Boolean).join(' ');
            const providerName = fullName || provider.business_name;
            return {
                provider_id: provider._id,
                provider_name: providerName,
                provider_type: provider.provider_type
            };
        });
        return { provider: transformedProviders };
    };

    const handleSubmit = () => {
        setLoading(true)
        const payload = transformData(selectedProviders);
        axiosInstance
            .patch(`${ApiEndpoints.PROVIDERS.addProviders}${id}/add-provider`, payload)
            .then((response) => response.data)
            .then((response) => {
                toast.success(response?.message);
                localStorage.removeItem('providerDetailsSelected');
                if (path === 'memberDetails') {
                    navigate(-1)
                } else {
                    navigate('/portfolio/added-member-list');
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            {loading ? <Loader /> :
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>
                        <Card>
                            <CardContent>
                                <Grid container xs={12} spacing={6}>
                                    <Grid item xs={12} md={8}>
                                        <Box>
                                            <Typography variant='fm-h6' color='neutral.80' fontWeight={600}>
                                                <Translations text={"Select Your General Practitioner"} />
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item container xs={12} md={4} spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="country-autocomplete"
                                                    options={countryList}
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={handleCountryChange}
                                                    renderOption={(props, option) => (
                                                        <li {...props}>
                                                            {option.name}
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Country"
                                                            variant="outlined"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {countryLoading && <CircularProgress size={20} color="inherit" />}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="state-autocomplete"
                                                    options={statesList}
                                                    getOptionLabel={(option) => option.name}
                                                    renderOption={(props, option) => (
                                                        <li {...props}>
                                                            {option.name}
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="State"
                                                            variant="outlined"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {statesLoading && <CircularProgress size={20} color="inherit" />}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {providerList?.length > 0 && providerList.map((item, index) => (
                                        <>
                                            <Grid item xs={12} md={6} sx={{
                                                display: 'flex',
                                                width: '100%',
                                                gap: '16px'
                                            }}>
                                                <Avatar
                                                    src={item.image}
                                                    variant="rounded"
                                                    sx={{ width: 130, height: 135, borderRadius: '20px' }}
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                    <Typography variant='fm-h5' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                        {item?.first_name && item?.middle_name && item?.last_name ? `${item?.first_name} ${item?.middle_name} ${item?.last_name}` : item?.business_name}
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600} sx={{ display: "flex", gap: "8px" }}>
                                                        <Translations text="Email" />
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            : <Translations text={item?.email} />
                                                        </Typography>
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Phone" />
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            : <Translations text={item?.phone_number} />
                                                        </Typography>
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="City" />
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                            : <Translations text={item?.city} />
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item container xs={12} md={6} spacing={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                                                <Grid item xs={6} md={4} >
                                                    <Button
                                                        variant="contained"
                                                        sx={{ color: '#ffffff', padding: "10px", backgroundColor: selectedProviders.includes(item) ? '#7e919b' : '#7339aa' }}
                                                        fullWidth
                                                        onClick={() => handleProviderSelection(item)}
                                                    >
                                                        <Typography variant='fm-p3' fontWeight={600}>
                                                            <Translations text={selectedProviders.includes(item) ? "Remove" : "Select"} />
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6} md={4} >
                                                    <Button sx={{ backgroundColor: '#EBF3F7', color: '#0B1B22', padding: "10px" }}
                                                        fullWidth
                                                        onClick={() => {
                                                            navigate(`/portfolio/general-practitioner-detail/${item?._id}?member=${id}`, {
                                                                state: {
                                                                    providerSelected: selectedProviders,
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <Typography variant='fm-p3' color='neutral.80' fontWeight={600}>
                                                            <Translations text="View Details" />
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Divider flexItem />
                                            </Grid>
                                        </>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                    {selectedProviders?.length > 0 && (
                        <div style={{ position: 'fixed', bottom: 0, left: '250px', right: '0px' }}>
                            <Box sx={{ backgroundColor: '#fff', height: '50px', width: '100%', padding: '5px', textAlign: 'end', position: 'sticky', bottom: 0 }}>
                                <Button onClick={() => { path === 'memberDetails' ? handleSubmit() : toggleConfirmDialog() }} variant="contained" size="large" sx={{ marginRight: '30px', textDecoration: 'none' }}>
                                    Submit
                                </Button>
                            </Box>
                        </div>
                    )}
                </>
            }
            <ProviderConfirmDialog
                open={isConfirmDialogOpen}
                toggle={toggleConfirmDialog}
                handleConfirm={handleSubmit}
            />
            <SelectDialog
                open={isSelectDialogOpen}
                toggle={toggleSelectDialog}
            />
        </>
    );
}

export default GeneralPractitionerList;
