import { Box, Card, CardContent, Checkbox, FormControl, FormLabel, Grid, TextField, Typography } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles'
import useCustomTranslation from "src/@core/hooks/useCustomTranslation";
import { useEffect, useState } from "react";

const CommonPlan = ({ serviceList, setSelectedPlanDetails }) => {

    const theme = useTheme()
    const translation = useCustomTranslation()


    // const [services, setServices] = useState([])
    const [bulletPoints, setBulletPoints] = useState(null)
    const [mainService, setMainService] = useState(null)
    const [extraService, setExtraService] = useState(null)
    const [extraServiceDescription, setExtraServiceDescription] = useState('')

    const [inputValues, setInputValues] = useState({});

    const handleInputChange = (e, serviceName) => {
        setInputValues(prevState => ({
            ...prevState,
            [serviceName]: e.target.value,
        }));
    };


    const [extraInputValues, setExtraInputValues] = useState({});
    const [checkedItems, setCheckedItems] = useState({});

    // Handler function to update input values
    const handleExtraInputChange = (e, serviceName) => {
        setExtraInputValues(prevState => ({
            ...prevState,
            [serviceName]: e.target.value,
        }));
    };

    // Handler function to toggle checkbox state
    const handleCheckboxChange = (e, serviceName) => {
        const isChecked = e.target.checked;
        setCheckedItems(prevState => ({
            ...prevState,
            [serviceName]: isChecked,
        }));
    };

    useEffect(() => {
        if (serviceList?.length > 0) {
            setBulletPoints(serviceList[0]?.bullet_plan);
            setMainService(serviceList[0]?.services)
            setExtraService(serviceList[0]?.extra_services)
            setExtraServiceDescription(serviceList[0]?.extra_services?.description)
            // setServices(serviceList)
        }
    }, [serviceList])

    useEffect(() => {

        // let mainServiceData = [inputValues]
        // mainServiceData.forEach(update => {
        //     for (const [key, value] of Object.entries(update)) {
        //         mainService.forEach(service => {
        //             if (service.title === key) {
        //                 service.visit = parseInt(value);
        //                 service.is_visit = true;
        //             }
        //         });
        //     }
        // });

        let extraServiceData = [extraInputValues]
        extraServiceData.forEach(update => {
            for (const [key, value] of Object.entries(update)) {
                extraService?.services.forEach(service => {
                    if (service.title === key) {
                        service.visit = parseInt(value);
                        service.is_visit = true;
                    }
                });
            }
        });


        const updatedMainService = mainService?.map(({ service_id, _id, ...rest }) => rest);
        const updatedExtraService = extraService?.services?.map(({ service_id, _id, ...rest }) => rest);
        const updatedBulletPlan = bulletPoints?.services?.length > 0 ? bulletPoints?.services?.map(({ service_id, _id, ...rest }) => rest) : null;

        const updatedServices = {
            extraService: updatedExtraService,
            mainService: updatedMainService,
            bulletPlan: updatedBulletPlan
        };


        setSelectedPlanDetails(updatedServices)
    }, [extraInputValues, inputValues, setSelectedPlanDetails, bulletPoints])



    return (
        <>
            <Grid container xs={12} spacing={6} mt={5}>
                <Grid item xs={12}>
                    <Typography variant="fm-h6" color='neutral.80' fontWeight={500}>
                        {/* {translation.translate("Basic Plan Details")} */}
                        {`${serviceList?.length > 0 ? serviceList[0].title : ''} Details`}
                    </Typography>
                    <Card sx={{ borderRadius: "12px", mt: 5 }}>
                        <CardContent >
                            {/* <Grid container spacing={6}>
                                <Grid item container xs={12} md={6} spacing={2}>
                                    <Grid item xs={12} >
                                        <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} /> <Typography variant="fm-p2" color='neutral.90'>100% Coverage except Pelvic exam covered at 50%</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} /> <Typography variant="fm-p2" color='neutral.90'>2 sick visit/year</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} /> <Typography variant="fm-p2" color='neutral.90'>1 Colonoscopy/5 years for men above 45 years old</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container xs={12} md={6} spacing={2}>
                                    <Grid item xs={12} >
                                        <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} /> <Typography variant="fm-p2" color='neutral.90'>1 physical visit/year</Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} /> <Typography variant="fm-p2" color='neutral.90'>1 PSA/year for men</Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} /> <Typography variant="fm-p2" color='neutral.90'>1 Endoscopy/3 years for men above 45 years old</Typography>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            <Grid container spacing={4}>
                                {bulletPoints?.services?.length > 0 && (
                                    <>
                                        {bulletPoints?.services.map((item, index) => (
                                            <Grid key={index} item container xs={12} md={6} spacing={2}>
                                                <Grid item xs={12}>
                                                    <CircleIcon sx={{ fontSize: '12px', marginRight: '5px', color: theme.palette.primary.main }} />
                                                    <Typography variant="fm-p2" color='neutral.90'>{item.title}</Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="fm-p2">
                        {bulletPoints?.description}
                    </Typography>
                </Grid>

                <Grid item xs={12} >
                    <Typography variant="fm-h6" color='neutral.80' fontWeight={500}>
                        {translation.translate("Main Service")}
                    </Typography>
                </Grid>
                <Grid item container xs={12} spacing={6} >
                    {mainService?.length > 0 && (
                        <>
                            {mainService.map((service, index) => {
                                return (
                                    <Grid item xs={12} md={6} key={index}>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} />
                                            <Typography variant="fm-p2" color='neutral.90'>
                                                {service?.title}
                                            </Typography>
                                        </Box>
                                        <Box mt={'12px'}>
                                            <FormControl sx={{ mb: 4 }}>
                                                <FormLabel htmlFor={`visits-${index}`}>{translation.translate("Number of Visits")}</FormLabel>
                                                <TextField
                                                    type="text"
                                                    // value={inputValues[service.title] || ''}
                                                    value={service.visit}
                                                    onChange={(e) => handleInputChange(e, service.title)}
                                                    id={`visits-${index}`}
                                                    disabled
                                                    sx={{
                                                        width: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'center',
                                                        }
                                                    }}
                                                />
                                                {/* {errors.visits && (
                                                    <FormHelperText sx={{ color: 'error.main' }}>
                                                        <Translations text={`form.visits.error.${errors.visits.message}`} />
                                                    </FormHelperText>
                                                )} */}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </>
                    )}
                </Grid>

                <Grid item xs={12} >
                    <Typography variant="fm-h6" color='neutral.80' fontWeight={500}>
                        {translation.translate("Extra Service")}
                    </Typography>
                </Grid>
                <Grid item container xs={12}>
                    {extraService?.services?.length > 0 && (
                        <>
                            {extraService?.services.map((service, index) => {
                                return (
                                    <Grid key={index} item xs={12} md={12}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox
                                                checked={checkedItems[service.title] || false}
                                                onChange={(e) => handleCheckboxChange(e, service.title)}
                                            />
                                            {/* <CircleIcon sx={{ fontSize: "12px", color: theme.palette.primary.main }} /> */}
                                            <Typography variant="fm-p2" color='neutral.90'>
                                                {service?.title}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <FormControl sx={{ mb: 4 }}>
                                                {checkedItems[service.title] && (
                                                    <>
                                                        <FormLabel htmlFor={`visits-${index}`}>{translation.translate("Number of Visits")}</FormLabel>
                                                        <TextField
                                                            type="number"
                                                            // value={extraInputValues[service.title] || ''}
                                                            value={service.visit}
                                                            onChange={(e) => handleExtraInputChange(e, service.title)}
                                                            id={`visits-${index}`}
                                                            sx={{
                                                                width: '50%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                '& .MuiInputBase-input': {
                                                                    textAlign: 'center',
                                                                }
                                                            }}
                                                            required
                                                            disabled
                                                        />
                                                    </>
                                                )}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </>
                    )}
                </Grid>

                <Grid item xs={12} >
                    <Typography variant="fm-p2" color='neutral.70' fontWeight={400}>
                        {extraServiceDescription}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default CommonPlan