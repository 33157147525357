export const API_BASE_URL = 'https://dev.api.famyll.com/planowner-ms/api/v1/'
// export const API_BASE_URL = 'https://062a-2405-201-2046-c806-2ef-531a-f20-1322.ngrok-free.app/planowner-ms/api/v1/'

export const ApiEndpoints = {
  AUTH: {
    register: `${API_BASE_URL}auth/sign-up`,
    login: `${API_BASE_URL}auth/sign-in`,
    forgotPassword: `${API_BASE_URL}auth/forgot-password`,
    verifyOtp: `${API_BASE_URL}auth/verify-otp`,
    resendOtp: `${API_BASE_URL}auth/resend-otp`,
    resetPassword: `${API_BASE_URL}auth/reset-password`,
    changePassword: `${API_BASE_URL}auth/change-password`,
    me: `${API_BASE_URL}auth/me`,
    notifications: `${API_BASE_URL}auth/notification`
  },
  GET_COUNTRY_STATE: {
    country: `https://countriesnow.space/api/v0.1/countries/flag/unicode`,
    state: `https://countriesnow.space/api/v0.1/countries/states`,
    city: `https://countriesnow.space/api/v0.1/countries/state/cities`
  },
  GET_REGION: {
    country: `https://api.countrystatecity.in/v1/countries`,
    state: `https://api.countrystatecity.in/v1/countries/`,
  },
  MEMBER: {
    addMember: `${API_BASE_URL}member/`,
    plans: `${API_BASE_URL}member/plans`,
    updateMember: (id) => `${API_BASE_URL}member/${id}`,
    getMyCartList: `${API_BASE_URL}member`,
    getMyCartListById: (member_id) => `${API_BASE_URL}member/${member_id}`,
    getServiceList: `${API_BASE_URL}service/`,
    getPackages: `${API_BASE_URL}/package/`,
    addedMemberList: `${API_BASE_URL}/member/latest-added-members?is_payment=false`,
    createPlan: `${API_BASE_URL}member/`,
    getMembers: `${API_BASE_URL}/member/latest-added-members?plan_added=true&is_payment=false`,
    payment: `${API_BASE_URL}member/make-payment`,
    removeProvider: `${API_BASE_URL}member/`,
    stripePayment: `${API_BASE_URL}payment/checkout/member/`
  },
  PROVIDERS: {
    getAllProviders: `${API_BASE_URL}provider/`,
    addProviders: `${API_BASE_URL}member/`,
    getProviderById: (id) => `${API_BASE_URL}provider/${id}`,
  }
}
