export const PublicRoutes = [];
export const ProfileSupported = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'video/mp4', 'video/mov', 'video/avi', 'video/mkv']
export const ProfileSupportedDropzone = { 'image/jpeg': [], 'image/png': [], 'image/jpg': [], 'image/gif': [], 'video/mp4': [], 'video/mov': [], 'video/avi': [], 'video/mkv': [] }
export const Relation = [
    { index: 1, label: "Brother", value: "brother" },
    { index: 2, label: "Sister", value: "sister" },
    { index: 3, label: "Cousin", value: "cousin" },
    { index: 4, label: "Niece", value: "niece" },
    { index: 5, label: "Nephew", value: "nephew" },
    { index: 6, label: "Friend", value: "friend" },
    { index: 7, label: "Uncle", value: "uncle" },
    { index: 8, label: "Aunt", value: "aunt" },
    { index: 9, label: "Mother", value: "mother" },
    { index: 10, label: "Father", value: "father" },
    { index: 11, label: "Husband", value: "husband" },
    { index: 12, label: "Wife", value: "wife" },
    { index: 13, label: "Son", value: "son" },
    { index: 14, label: "Daughter", value: "daughter" },
    { index: 15, label: "Grand Uncle", value: "granduncle" },
    { index: 16, label: "Grand Aunt", value: "grandaunt" },
    { index: 17, label: "Grandmother", value: "grandmother" },
    { index: 18, label: "Grandfather", value: "grandfather" },
    { index: 19, label: "Father-in-law", value: "fatherinlaw" },
    { index: 20, label: "Mother-in-law", value: "motherinlaw" },
    { index: 21, label: "Daughter-in-law", value: "daughterinlaw" },
    { index: 22, label: "Son-in-law", value: "soninlaw" },
    { index: 23, label: "Brother-in-law", value: "brotherinlaw" },
    { index: 24, label: "Sister-in-law", value: "sisterinlaw" },
    { index: 25, label: "Aunt-in-law", value: "auntinlaw" },
    { index: 26, label: "Uncle-in-law", value: "uncleinlaw" },
];
export const PlanStatus = [
    { index: 1, label: "No Plan", value: "no_plan", color: 'grey', bg: '#e3e3e3' },
    { index: 2, label: "Active", value: "active", color: '#1ea922', bg: '#d6ffcf' },
    { index: 3, label: "Waiting", value: "waiting", color: '#ff8400', bg: '#ffdcb7' },
    { index: 4, label: "Covered", value: "covered", color: 'purple', bg: '#dbc0db' },
    { index: 5, label: "Suspended", value: "suspended", color: 'red', bg: '#ffbbbb' },
]