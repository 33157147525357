import { Avatar, Box, Button, Card, CardContent, Divider, Grid, Typography, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import Translations from "src/layouts/components/Translations"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosInstance } from "src/network/adapter";
import { ApiEndpoints } from "src/network/endpoints";
import toast from "react-hot-toast";
import moment from "moment";
import Loader from "src/@core/components/loader";

const CustomLink = styled(Link)(({ theme }) => ({
    color: 'inherit',
    ' &:hover, &:focus, &:active': {
        color: 'inherit'
    }
}))

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    // marginBottom: 10,
    padding: theme.spacing(0, 4),
    minHeight: theme.spacing(12),
    transition: 'min-height 0.15s ease-in-out',
    // backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.background.default,
    // borderBottom:
    //   theme.palette.mode === 'light' ? `1px solid ${theme.palette.grey[300]}` : `1px solid ${theme.palette.divider}`,
    '&.Mui-expanded': {
        minHeight: theme.spacing(12)
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '10px 0'
    }
}))

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    padding: theme.spacing(2),
    boxShadow: 'none !important',
    // border:
    //     theme.palette.mode === 'light' ? `1px solid ${theme.palette.grey[300]}` : `1px solid ${theme.palette.divider}`,
    // '&:not(:last-of-type)': {
    //     borderBottom: 0
    // },
    borderBottom: theme.palette.mode === 'light' ? `1px solid ${theme.palette.grey[300]}` : `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none'
    },
    '&.Mui-expanded': {
        margin: 'auto'
    },
    '&:first-of-type': {
        '& .MuiButtonBase-root': {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius
        }
    },
    '&:last-of-type': {
        '& .MuiAccordionSummary-root:not(.Mui-expanded)': {
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius
        }
    }
}))

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    // padding: theme.spacing(4),
}))

const GeneralPractitionerDetail = () => {
    const { id } = useParams()
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const memberId = queryParams.get('member');
    const navigate = useNavigate()

    // const [isSelectDialogOpen, setSelectDialogOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [providerDetails, setProviderDetails] = useState(null)
    const [isSelected, setIsSelected] = useState(false)
    const [isStored, setIsStored] = useState(false)
    const [providersList, setProvidersList] = useState([])
    const { providerSelected, selectedProvider, path } = location.state || {};

    // const toggleSelectDialog = (e) => {
    //     setSelectDialogOpen(prev => !prev);
    // }


    useEffect(() => {
        const getProviderDetails = () => {
            setLoading(true);
            axiosInstance
                .get(ApiEndpoints.PROVIDERS.getProviderById(id))
                .then((response) => {
                    setProviderDetails(response?.data?.data?.providers)
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }

        if (id)
            getProviderDetails()
    }, [id])


    useEffect(() => {
        const getMemberDetailsById = (memberId) => {
            setLoading(true);
            axiosInstance
                .get(ApiEndpoints.MEMBER.getMyCartListById(memberId))
                .then((response) => {
                    const providers = response?.data?.data?.member?.provider ?? [];
                    setProvidersList(providers)
                    const isSelected = providers.some((provider) => provider?.provider_id === id);
                    setIsStored(isSelected);
                })
                .catch((error) => {
                    const errorMessage = error?.response?.data?.message ?? "An error occurred";
                    toast.error(errorMessage);
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        if (memberId) {
            getMemberDetailsById(memberId);
        }
    }, [memberId, id]);

    useEffect(() => {
        if (path === 'details') {
            if (selectedProvider?.length > 0) {
                const providers = selectedProvider ?? [];
                const isSelected = providers.some((provider) => provider?.provider_id === id);

                setIsSelected(isSelected);
            }
        }

        if (providerSelected?.length > 0) {
            const providers = providerSelected ?? [];
            const isSelected = providers.some((provider) => provider?._id === id);

            setIsSelected(isSelected);
        }
    }, [id, providerSelected, selectedProvider, path])


    const handleChangeSelection = () => {
        if (id) {
            setIsSelected(true);
            navigate(-1);
            let providerSelectedList = JSON.parse(localStorage.getItem('providerDetailsSelected')) || []; // Retrieve the current list of providers from localStorage, or initialize as an empty array if not found
            providerSelectedList.push(id); // Add the new provider to the list
            localStorage.setItem('providerDetailsSelected', JSON.stringify(providerSelectedList)); // Save the updated list back to localStorage
        }
    };

    const handleChangeRemove = (idToRemove) => {
        if (isStored) {
            setLoading(true)
            axiosInstance.patch(`${ApiEndpoints.MEMBER.removeProvider}${memberId}/remove-provider/${idToRemove}`)
                .then((response) => {
                    navigate(-1);
                })
                .catch((error) => {
                    toast.error(error?.response);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            navigate(-1);
            let providerSelectedList = JSON.parse(localStorage.getItem('providerDetailsSelected')); // Retrieve the providerSelectedList from localStorage
            if (Array.isArray(providerSelectedList)) {
                providerSelectedList = providerSelectedList.filter(id => id !== idToRemove); // Remove the selected ID from the array
                localStorage.setItem('providerDetailsSelected', JSON.stringify(providerSelectedList)); // Save the updated array back to localStorage
            }
        }
    };

    const handleRemoveProvider = (provId) => {
        setLoading(true)
        axiosInstance.patch(`${ApiEndpoints.MEMBER.removeProvider}${memberId}/remove-provider/${provId}`)
            .then((response) => {
                navigate(-1);
                setLoading(false);
            })
            .catch((error) => {
                toast.error(error?.response);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <>
            {loading ? <Loader /> :
                <Card>
                    <CardContent>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={8} sx={{
                                display: 'flex',
                                width: '100%',
                                gap: '16px'
                            }}>

                                <Avatar
                                    src={'/assets/images/person.png'}
                                    variant="rounded"
                                    sx={{ width: 150, height: 150, borderRadius: '20px' }}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                    <Typography variant='fm-h5' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                        {providerDetails?.first_name && providerDetails?.middle_name && providerDetails?.last_name ?
                                            `${providerDetails?.first_name} ${providerDetails?.middle_name} ${providerDetails?.last_name}`
                                            :
                                            providerDetails?.business_name
                                        }
                                    </Typography>

                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600} sx={{ display: "flex", gap: "8px" }}>
                                        <Translations text="Provider Type" />
                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                            : {providerDetails?.provider_type}
                                        </Typography>
                                    </Typography>
                                    {providerDetails?.business_year &&
                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                            <Translations text="Business Year" />
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                : {providerDetails?.business_year}
                                            </Typography>
                                        </Typography>
                                    }

                                    {providerDetails?.graduation_date &&
                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                            <Translations text="Graduation Year" />
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                : {moment(providerDetails?.graduation_date).format('YYYY')}
                                            </Typography>
                                        </Typography>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {path === 'details' ? (
                                    <Button variant="contained" size="large" color="primary"
                                        onClick={() => {
                                            handleRemoveProvider(id)
                                        }}>
                                        <Typography variant="fm-p2" fontWeight={600}><Translations text={isSelected ? "Remove" : "Select"} /></Typography>
                                    </Button>
                                ) : (
                                    <Button variant="contained" size="large" color="primary"
                                        onClick={() => {
                                            if (isSelected) {
                                                handleChangeRemove(id)
                                            } else {
                                                handleChangeSelection()
                                            }
                                        }}>
                                        <Typography variant="fm-p2" fontWeight={600}><Translations text={isSelected ? "Remove" : "Select"} /></Typography>
                                    </Button>
                                )}


                            </Grid>

                            <Grid item xs={12}>
                                <Divider mt={0} />
                                <CustomAccordion defaultExpanded>
                                    <CustomAccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80', fontWeight: "400" }} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant="fm-h6" fontWeight={600}>
                                            <Translations text="Contact Information" />
                                        </Typography>
                                    </CustomAccordionSummary>

                                    <CustomAccordionDetails
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(5, 1fr)',
                                            gap: isSmallScreen ? '20px' : '0',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                            <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                <Translations text="Phone Number" />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                {providerDetails?.phone_number}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                            <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                <Translations text="Email Address" />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                {providerDetails?.email}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                            <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                <Translations text="City" />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                {providerDetails?.city}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                            <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                <Translations text="State/Province" />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                {providerDetails?.state}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                            <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                <Translations text="Country" />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                {providerDetails?.country}
                                            </Typography>
                                        </Box>
                                    </CustomAccordionDetails>
                                </CustomAccordion>

                                {providerDetails?.medicalSchool?.length > 0 && (
                                    <CustomAccordion>
                                        <CustomAccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography variant="fm-h6" fontWeight={600}>Medical School Information</Typography>
                                        </CustomAccordionSummary>

                                        <CustomAccordionDetails
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                                gap: isSmallScreen ? '20px' : '0',
                                            }}
                                        >
                                            {providerDetails?.medicalSchool.map((item, index) => (
                                                <Box key={index} sx={{ display: 'flex', flexDirection: "column" }}>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Name" />
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                        {item?.name}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </CustomAccordionDetails>
                                    </CustomAccordion>
                                )}

                                {providerDetails?.available_specialist?.length > 0 && (
                                    <CustomAccordion>
                                        <CustomAccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography variant="fm-h6" fontWeight={600}>Area of Specialist</Typography>
                                        </CustomAccordionSummary>

                                        <CustomAccordionDetails
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                                gap: isSmallScreen ? '20px' : '0',
                                            }}
                                        >
                                            {providerDetails?.available_specialist.map((item, index) => (
                                                <Box key={index} sx={{ display: 'flex', flexDirection: "column" }}>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Name" />
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                        {item.specialist_name}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </CustomAccordionDetails>
                                    </CustomAccordion>
                                )}

                                {providerDetails?.pharmacies?.length > 0 && (
                                    <CustomAccordion>
                                        <CustomAccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography variant="fm-h6" fontWeight={600}>Pharmacies Information </Typography>
                                        </CustomAccordionSummary>

                                        <CustomAccordionDetails
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                                gap: isSmallScreen ? '20px' : '0',
                                            }}
                                        >
                                            {providerDetails?.pharmacies.map((item, index) => (
                                                <Box key={index} sx={{ display: 'flex', flexDirection: "column" }}>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Name" />
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                        {item?.pharmacies_name}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </CustomAccordionDetails>
                                    </CustomAccordion>
                                )}

                                <CustomAccordion>
                                    <CustomAccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant="fm-h6" fontWeight={600}>
                                            <Translations text="Business Information" />
                                        </Typography>
                                    </CustomAccordionSummary>

                                    <CustomAccordionDetails
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                            gap: isSmallScreen ? '20px' : '0',
                                        }}
                                    >
                                        {providerDetails?.business_name && (
                                            <Box sx={{ display: 'flex', flexDirection: "column" }}>
                                                <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                    <Translations text="Name" />
                                                </Typography>
                                                <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                    {providerDetails?.business_name}
                                                </Typography>
                                            </Box>
                                        )}

                                        {providerDetails?.business_license_number && (
                                            <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                    <Translations text="License Number" />
                                                </Typography>
                                                <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                    {providerDetails?.business_license_number}
                                                </Typography>
                                            </Box>
                                        )}

                                        {providerDetails?.business_address && (
                                            <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                    <Translations text="Business Address" />
                                                </Typography>
                                                <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                    {providerDetails?.business_address}
                                                </Typography>
                                            </Box>
                                        )}

                                        {providerDetails?.business_license_expiry_date && (
                                            <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px", marginTop: '3px' }}>
                                                <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                    <Translations text="License Expiry Date" />
                                                </Typography>
                                                <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'uppercase' }}>
                                                    {moment(providerDetails?.business_license_expiry_date).format('DD-MM-YYYY')}
                                                </Typography>
                                            </Box>
                                        )}

                                    </CustomAccordionDetails>
                                </CustomAccordion>

                                {providerDetails?.primary_clinic_name &&
                                    providerDetails?.primary_clinic_phone_number && providerDetails?.primary_clinic_business_address && (

                                        <CustomAccordion>
                                            <CustomAccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography variant="fm-h6" fontWeight={600}>Primary Clinic</Typography>
                                            </CustomAccordionSummary>

                                            <CustomAccordionDetails
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                                    gap: isSmallScreen ? '20px' : '0',
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: "column" }}>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Name" />
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                        {providerDetails?.primary_clinic_name}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Phone Number" />
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                        {providerDetails?.primary_clinic_phone_number}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Business Address" />
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                        {providerDetails?.primary_clinic_business_address}
                                                    </Typography>
                                                </Box>
                                            </CustomAccordionDetails>
                                        </CustomAccordion>

                                    )}

                                {providerDetails?.primary_hospital_name &&
                                    providerDetails?.primary_hospital_phone_number && providerDetails?.primary_hospital_business_address && (
                                        <CustomAccordion>
                                            <CustomAccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography variant="fm-h6" fontWeight={600}>
                                                    <Translations text="Primary Hospital" />
                                                </Typography>
                                            </CustomAccordionSummary>

                                            <CustomAccordionDetails
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                                    gap: isSmallScreen ? '20px' : '0',
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Name" />
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                        {providerDetails?.primary_hospital_name}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Phone Number" />
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                        {providerDetails?.primary_hospital_phone_number}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                        <Translations text="Business Address" />
                                                    </Typography>
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                        {providerDetails?.primary_hospital_business_address}
                                                    </Typography>
                                                </Box>
                                            </CustomAccordionDetails>
                                        </CustomAccordion>
                                    )}
                                {providerDetails?.affiliatedHospitals?.length > 0 && (
                                    <CustomAccordion>
                                        <CustomAccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography variant="fm-h6" fontWeight={600}>
                                                <Translations text="Affiliated Hospital" />
                                            </Typography>
                                        </CustomAccordionSummary>

                                        <CustomAccordionDetails
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                                gap: isSmallScreen ? '20px' : '0',
                                            }}
                                        >
                                            {providerDetails?.affiliatedHospitals.map((item) => {
                                                return (<>
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600} >
                                                            <Translations text="Name" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                            {item?.business_name}
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                            <Translations text="Business Address" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                            {item?.business_address}
                                                        </Typography>
                                                    </Box>
                                                </>)
                                            })}


                                        </CustomAccordionDetails>
                                    </CustomAccordion>
                                )}
                                {providerDetails?.affiliatedClinic?.length > 0 && (
                                    <CustomAccordion>
                                        <CustomAccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography variant="fm-h6" fontWeight={600}>
                                                <Translations text="Affiliated Clinic" />
                                            </Typography>
                                        </CustomAccordionSummary>

                                        <CustomAccordionDetails
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                                gap: isSmallScreen ? '20px' : '0',
                                            }}
                                        >
                                            {providerDetails?.affiliatedClinic.map((item) => {
                                                return (<>
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600} >
                                                            <Translations text="Name" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                            {item?.business_name}
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                            <Translations text="Business Address" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                            {item?.business_address}
                                                        </Typography>
                                                    </Box>
                                                </>)
                                            })}
                                        </CustomAccordionDetails>
                                    </CustomAccordion>
                                )}

                                {
                                    (providerDetails?.business_hours_to && providerDetails?.business_hours_from) ||
                                        (providerDetails?.call_day_to && providerDetails?.call_day_from) ||
                                        providerDetails?.consultation_hours ||
                                        providerDetails?.consultation_days ? (
                                        <CustomAccordion>
                                            <CustomAccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography variant="fm-h6" fontWeight={600}>
                                                    <Translations text="Consultation" />
                                                </Typography>
                                            </CustomAccordionSummary>

                                            <CustomAccordionDetails
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                                    gap: isSmallScreen ? '20px' : '0',
                                                }}
                                            >
                                                {providerDetails?.business_hours_to && providerDetails?.business_hours_from && (
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600} >
                                                            <Translations text="Business Hours" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'uppercase' }}>
                                                            {`${providerDetails?.business_hours_to} - ${providerDetails?.business_hours_from}`}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {providerDetails?.call_day_to && providerDetails?.call_day_from && (
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                            <Translations text="Call Days" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                            {`${providerDetails?.call_day_to} - ${providerDetails?.call_day_from}`}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {providerDetails?.consultation_hours && (
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600} >
                                                            <Translations text="Consultation Hours" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'uppercase' }}>
                                                            {providerDetails?.consultation_hours}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {providerDetails?.consultation_days && (
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600} >
                                                            <Translations text="Consultation Days" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                                                            {providerDetails?.consultation_days}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </CustomAccordionDetails>
                                        </CustomAccordion>
                                    ) : null
                                }

                                {
                                    (providerDetails?.number_of_beds ||
                                        providerDetails?.number_of_general_practice_doctors ||
                                        providerDetails?.number_of_specialist_doctors ||
                                        providerDetails?.medical_specialization) && (
                                        <CustomAccordion>
                                            <CustomAccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px", color: 'neutral.80' }} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography variant="fm-h6" fontWeight={600}>
                                                    <Translations text="Other Information" />
                                                </Typography>
                                            </CustomAccordionSummary>
                                            <CustomAccordionDetails
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(3, 1fr)',
                                                    gap: isSmallScreen ? '20px' : '0',
                                                }}
                                            >
                                                {providerDetails?.number_of_beds && (
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                            <Translations text="Number Of Beds" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {providerDetails?.number_of_beds}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {providerDetails?.number_of_general_practice_doctors && (
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                            <Translations text="Number Of General Practice Doctors" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {providerDetails?.number_of_general_practice_doctors}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {providerDetails?.number_of_specialist_doctors && (
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                            <Translations text="Number Of Specialist Doctors" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {providerDetails?.number_of_specialist_doctors}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {providerDetails?.medical_specialization && (
                                                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                                                            <Translations text="Medical Specialization" />
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {providerDetails?.medical_specialization}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </CustomAccordionDetails>
                                        </CustomAccordion>
                                    )
                                }
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            }
        </>
    )
}
export default GeneralPractitionerDetail