import { Avatar, Box, Button, Card, CardContent, Divider, FormControl, Grid, Select, Typography, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import Loader from "src/@core/components/loader"
import useCustomTranslation from "src/@core/hooks/useCustomTranslation"
import Translations from "src/layouts/components/Translations"
import { axiosInstance } from "src/network/adapter"
import { ApiEndpoints } from "src/network/endpoints"
import PaymentConfirm from "./dialog/paymentConfirm"
import { useTheme } from '@mui/material/styles'
function MakePayment() {
    const translation = useCustomTranslation()
    const [memberList, setMemberList] = useState([])
    const [loading, setLoading] = useState(false)
    const [isOpenPaymentDialog, setIsOpenPaymentDialog] = useState(false)
    const [total, setTotal] = useState(0)
    const [singleMember, setSingleMember] = useState(null)
    const navigate = useNavigate();


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));

    // const getAllAddedMembers = () => {
    //     setLoading(true);
    //     axiosInstance
    //         .get(ApiEndpoints.MEMBER.getMembers)
    //         .then((response) => {
    //             let list = response?.data?.data?.members
    //             const totalAmount = list.reduce((accumulator, item) => {
    //                 return accumulator + (item.total_amount || 0);
    //             }, 0);

    //             setTotal(totalAmount)
    //             setMemberList(list)
    //         })
    //         .catch((error) => {
    //             toast.error(error?.response?.data?.message);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };

    const getAllAddedMembers = () => {
        setLoading(true);
        axiosInstance
            .get(ApiEndpoints.MEMBER.getMembers)
            .then((response) => {
                let list = response?.data?.data?.members;
                console.log(list[0]);
                if (list && list.length > 0) {
                    setSingleMember(list[0]); // Set the single record with the first record
                    const totalAmount = list[0].total_amount || 0;
                    setTotal(totalAmount);
                } else {
                    setTotal(0);
                }

                setMemberList(list);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getAllAddedMembers()
    }, [])

    const handlePayment = () => {
        const payload = {
            member: memberList.map(item => ({
                member_id: item._id
            }))
        };
        setLoading(true)
        axiosInstance
            .patch(ApiEndpoints.MEMBER.payment, payload)
            .then((response) => response.data)
            .then((response) => {
                // togglePaymentConfirmDialog()
                setLoading(true)
                localStorage.removeItem('memberDetailsId');
                setTimeout(() => {
                    createStripePayment()
                }, 400);
                // toast.success(response?.message);
                // navigate('/')
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const createStripePayment = () => {
        setLoading(true)
        axiosInstance
            .post(`${ApiEndpoints.MEMBER.stripePayment}${memberList[0]?._id}`)
            .then((response) => response.data)
            .then((response) => {
                let url = response?.data?.sessionUrl
                window.location.href = url;
                // window.open(url, '_blank');
                // toast.success(response?.message);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const togglePaymentConfirmDialog = () => {
        setIsOpenPaymentDialog(prev => !prev)
    }





    return (
        <>
            {loading ? <Loader /> : <>
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', position: 'relative' }}>
                    <Box sx={{ flex: 1, overflowY: 'auto', pb: '80px' }}> {/* Add padding-bottom to the scrollable box */}
                        {/* {memberList?.length > 0 && memberList.map((item, index) => (
                            <Card sx={{ my: 3 }} key={index}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={2}>
                                            <Avatar
                                                src={item.image}
                                                variant="rounded"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: '200px', // Set maximum width to maintain consistency
                                                    height: 'auto',
                                                    borderRadius: '20px'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <Typography variant='fm-p2' color='neutral.60' fontWeight={500}>
                                                            #Member{index + 1}
                                                        </Typography>
                                                        <Typography variant='fm-h6' color='neutral.80' fontWeight={600}>
                                                            {`${item.first_name} ${item.middle_name} ${item.last_name}`}
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>

                                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <Typography variant='fm-p2' color='theme.palette.text.grey' fontWeight={500}>
                                                            {translation.translate("Selected Coverage Plan")}
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='theme.palette.text.grey' fontWeight={500}>
                                                            {translation.translate("Coverage Plan Fee")}
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='theme.palette.text.grey' fontWeight={500}>
                                                            {translation.translate("Membership Fee")}
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: "flex-end" }}>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {item.plan_type}
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {item.annual_amount ? `$${item.annual_amount}` : '$0'}
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {item.annual_membership_amount ? `$${item.annual_membership_amount}` : '$0'}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                    <div style={{ position: 'fixed', bottom: 0, left: '250px', right: '0px', zIndex: 1 }}>
                        <Box sx={{ width: '100%', background: '#FFF', p: '16px 24px', boxShadow: '0 -2px 10px rgba(0,0,0,0.1)' }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        {memberList.length >= 5 && (
                                            <Typography variant='fm-p1' color='neutral.60' fontWeight={500}>
                                                <Translations text="Total Amount (Applied 10% discount for adding 5 or more than 5 members.)" />
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box>
                                        <Typography variant='fm-h5' color='neutral.60' fontWeight={600}>
                                            ${total}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} display='flex' justifyContent={{ md: 'flex-end', xs: 'flex-start' }} gap={2}>
                                    <Button onClick={() => navigate('/portfolio/add-member')} sx={{ textDecoration: 'none', width: { xs: '100%', sm: '30%' }, padding: "12px 24px" }} variant='outlined'>
                                        <Typography variant='fm-p2' fontWeight={600}>
                                            <Translations text="Add More Member" />
                                        </Typography>
                                    </Button>
                                    <Button onClick={createStripePayment} sx={{ width: { xs: '100%', sm: '30%' }, padding: "12px", display: "flex", justifyContent: "center" }} variant='contained'>
                                        <Typography variant='fm-p2' fontWeight={600}>
                                            <Translations text="Complete Payment" />
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>
            </>} */}


                        {singleMember && (
                            <Card sx={{ my: 3 }} key={singleMember._id}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={2}>
                                            <Avatar
                                                src={singleMember.image}
                                                variant="rounded"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: '200px', // Set maximum width to maintain consistency
                                                    height: 'auto',
                                                    borderRadius: '20px'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <Typography variant='fm-p2' color='neutral.60' fontWeight={500}>
                                                            #Member1
                                                        </Typography>
                                                        <Typography variant='fm-h6' color='neutral.80' fontWeight={600}>
                                                            {`${singleMember.first_name} ${singleMember.middle_name} ${singleMember.last_name}`}
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>

                                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <Typography variant='fm-p2' color='theme.palette.text.grey' fontWeight={500}>
                                                            {translation.translate("Selected Coverage Plan")}
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='theme.palette.text.grey' fontWeight={500}>
                                                            {translation.translate("Coverage Plan Fee")}
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='theme.palette.text.grey' fontWeight={500}>
                                                            {translation.translate("Membership Fee")}
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: "flex-end" }}>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {singleMember.plan_type}
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {singleMember.annual_amount ? `$${singleMember.annual_amount}` : '$0'}
                                                        </Typography>
                                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                                            {singleMember.annual_membership_amount ? `$${singleMember.annual_membership_amount}` : '$0'}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )}
                    </Box>
                    <div style={{ position: 'fixed', bottom: 0, left: isSmallScreen ? '0px' : '250px', right: '0px', zIndex: 1 }}>
                        <Box sx={{ width: '100%', background: '#FFF', p: '16px 24px', boxShadow: '0 -2px 10px rgba(0,0,0,0.1)' }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        {memberList.length >= 5 && (
                                            <Typography variant='fm-p1' color='neutral.60' fontWeight={500}>
                                                <Translations text="Total Amount (Applied 10% discount for adding 5 or more than 5 members.)" />
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box sx={{display:'flex', justifyContent:{xs:'center',md:'start'}}}>
                                        <Typography variant='fm-h5' color='neutral.60' fontWeight={600}>
                                            ${total}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} display='flex' flexDirection={{xs:'column', md:'row'}} justifyContent={{ md: 'flex-end', xs: 'flex-start' }} gap={2}>
                                    <Button onClick={() => navigate('/portfolio/add-member')} sx={{ textDecoration: 'none', width: 'auto', padding: "12px 24px" }} variant='outlined'>
                                        <Typography variant='fm-p2' fontWeight={600}>
                                            <Translations text="Add More Member" />
                                        </Typography>
                                    </Button>
                                    <Button onClick={handlePayment} sx={{ width: 'auto', padding: "12px 24px", display: "flex", justifyContent: "center" }} variant='contained'>
                                        <Typography variant='fm-p2' fontWeight={600}>
                                            <Translations text="Complete Payment" />
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>
            </>}


            <PaymentConfirm
                open={isOpenPaymentDialog}
                toggle={togglePaymentConfirmDialog}
                redirectUrl="/"
            />
        </>
    )
}
export default MakePayment