import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  styled,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  FormGroup,
  Button,
  FormHelperText,
  CircularProgress
} from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { ProfileSupported, ProfileSupportedDropzone, Relation } from 'src/utils/constants'
import CustomDropzone from 'src/views/fileUpload/CustomDropzone'
import Translations from 'src/layouts/components/Translations'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import Datepicker from 'src/views/CustomDatepicker'
import { useEffect, useRef, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CoveragePlan from 'src/views/coveragePlan'
import DeclarationConsentDialog from 'src/views/dialog/DeclarationConsentDialog'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { FormValidationMessages } from 'src/constants/form.const'
import { ApiEndpoints } from 'src/network/endpoints'
import { axiosInstance } from 'src/network/adapter'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'


import DatePicker from "react-datepicker";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { format } from "date-fns"; // Import the date-fns library for date formatting
import DatePickerWrapper from "src/@core/styles/libs/react-datepicker";
import toast from 'react-hot-toast'
import MemberConfirmDialog from 'src/views/dialog/memberConfirmDialog'
import Loader from 'src/@core/components/loader'


const validationSchema = yup.object().shape({
  profileImg: yup
    .mixed()
    .test('validFile', 'Profile image must be a valid file', value => {
      return (
        value === null ||
        typeof value === 'undefined' ||
        (value.size <= 1024 * 1024 * 3 && ProfileSupported.includes(value.type))
      )
    })
    .nullable()
    .required('Required'),
  firstName: yup.string().required(FormValidationMessages.FIRST_NAME.required).max(20, FormValidationMessages.FIRST_NAME.maxErrorMessage),
  middleName: yup.string().required(FormValidationMessages.MIDDLE_NAME.required).max(20, FormValidationMessages.MIDDLE_NAME.maxErrorMessage),
  lastName: yup.string().required(FormValidationMessages.LAST_NAME.required).max(20, FormValidationMessages.LAST_NAME.maxErrorMessage),
  dob: yup.string().required(FormValidationMessages.DOB.required),
  // email: yup.string().email(FormValidationMessages.EMAIL.invalid).required(FormValidationMessages.EMAIL.required),
  email: yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
    .max(50, FormValidationMessages.EMAIL.max)
    .required(FormValidationMessages.EMAIL.required),
  phone: yup
    .string()
    .matches(FormValidationMessages.PHONE.pattern, FormValidationMessages.PHONE.patternErrorMessage)
    // .matches(/^\d{10}$/, FormValidationMessages.PHONE.matchMessage)
    .required(FormValidationMessages.PHONE.required),
  cityName: yup.string().required(FormValidationMessages.CITY_NAME.required),
  state: yup.string().required(FormValidationMessages.STATE.required),
  country: yup.string().required(FormValidationMessages.COUNTRY.required),
  gender: yup.string().required('Gender is required'),
  isSmoker: yup.string().required('Smoker status is required'),
  // isUserConsent: yup
  //   .boolean()
  //   .oneOf([true], FormValidationMessages.IS_USER_CONSENT.required)
  //   .required(FormValidationMessages.IS_USER_CONSENT.required),
  preExistingConditions: yup
    .array()
    .min(1, "Please select at least one pre-existing condition or None."),

  relationship: yup.string().required('Relationship is required'),
})


const CustomRadioLabel = styled(FormControlLabel)(({ theme, checked }) => ({
  backgroundColor: checked ? theme.palette.bg.light : 'transparent',
  borderRadius: 99,
  '& .MuiFormControlLabel-label': {
    padding: '9px 9px 9px 0',
    marginLeft: '0px'
  }
}))


const checkboxOptions = [
  { value: "HIV/AIDS", label: "HIV/AIDS" },
  { value: "cancer", label: "Cancer" },
  { value: "diabetes", label: "Diabetes" },
  { value: "high_blood_pressure", label: "High Blood Pressure" },
  { value: "none", label: "None" },
];

function AddMemberForm({ mode }) {

  const translation = useCustomTranslation()
  const [isConsentDialogOpen, setConsentDialogOpen] = useState(false)
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [age, setAge] = useState(18)
  const [year, setYear] = useState('monthly')
  const [countries, setCountries] = useState(null)
  const [countryDetails, setCountryDetails] = useState(null)
  const [states, setStates] = useState([])
  const [loading, setLoading] = useState(false)

  const [isUserConsent, setIsUserConsent] = useState(false);

  const [noneChecked, setNoneChecked] = useState(false);
  const [otherChecked, setOtherChecked] = useState(false);
  const [confirmFlag, setConfirmFlag] = useState(false);


  const [countryList, setCountryList] = useState([])
  const [statesList, setStatesList] = useState([]);
  const [countryLoading, setCountryLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);

  const [showDialog, setShowDialog] = useState(true);
  const [previousClicked, setPreviousClicked] = useState(false);

  const [firstPreviousClick, setFirstPreviousClick] = useState(true);
  const [nextClicked, setNextClicked] = useState(false);


  const navigate = useNavigate()



  const [storedData, setStoredData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [uploadedFile, setUploadedFile] = useState(null);
  const [checkboxState, setCheckboxState] = useState({
    "HIV/AIDS": false,
    cancer: false,
    diabetes: false,
    high_blood_pressure: false,
    none: false,
  });


  const formRef = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    watch,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      profileImg: null,
      firstName: '',
      middleName: '',
      lastName: '',
      relationship: '',
      dob: null,
      gender: 'female',
      isSmoker: 'yes',
      email: '',
      phone: '',
      cityName: '',
      state: '',
      country: '',
      isUserConsent: false,
      preExistingConditions: [],
      age: age,
      year: 'monthly',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })
  const watch_isUserConsent = watch("isUserConsent")

  const getCountryList = async () => {
    setCountryLoading(true)
    axiosInstance
      .get(`${ApiEndpoints.GET_REGION.country}`, {
        headers: {
          'X-CSCAPI-KEY': 'RmYyOHVGMnBSS0VoRUc5cTRMMmhnc2UwdE1WdVZqaHRicnhMbzJ6eA==' // Replace 'API_KEY' with your actual API key
        }
      })
      .then((response) => response?.data)
      .then((response) => {
        setCountryList(response);
      })
      .catch((error) => {
        toast.error(error?.response);
      })
      .finally(() => {
        setCountryLoading(false);
      });
  };

  useEffect(() => {
    getCountryList();
  }, [])

  const handleCountryChange = async (event, value) => {
    if (value) {
      await getStateList(value?.iso2)
    }
  };


  const getStateList = async (val) => {
    try {
      setStatesLoading(true);
      const response = await axiosInstance.get(`${ApiEndpoints.GET_REGION.state}${val}/states`, {
        headers: {
          'X-CSCAPI-KEY': 'RmYyOHVGMnBSS0VoRUc5cTRMMmhnc2UwdE1WdVZqaHRicnhMbzJ6eA=='
        }
      });
      const responseData = response?.data;
      setStatesList(responseData);
      setStatesLoading(false);
      return responseData; // Return the data to be used later
    } catch (error) {
      toast.error(error.response.data.message);
      setStatesLoading(false);
      return null; // Return null or handle the error as needed
    }
  }




  const handleAgeChange = event => {
    setAge(event.target.value)
  }
  const handleYearChange = event => {
    setYear(event.target.value)
    setValue('year', event.target.value);
  }


  const [currentPage, setCurrentPage] = useState(1)
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)

  }


  const dob = watch('dob');

  useEffect(() => {
    const calculateAgeDifference = (selectedDate) => {
      if (selectedDate) {
        const dobDate = new Date(selectedDate);
        const today = new Date();
        const ageDifferenceInYears = today.getFullYear() - dobDate.getFullYear();

        if (ageDifferenceInYears < 18) {
          setAge('0-18');
        } else if (ageDifferenceInYears >= 18 && ageDifferenceInYears <= 44) {
          setAge('18-44');
        } else {
          setAge('45+');
        }
      }
    };

    calculateAgeDifference(dob);
  }, [dob]);



  const handleCheckboxChange = (value, field) => (e) => {
    if (value === "none" && e.target.checked) {
      setNoneChecked(true);
      setOtherChecked(false);
      field.onChange(["none"]);
      delete errors.preExistingConditions;
    } else if (value === "none" && !e.target.checked) {
      setNoneChecked(false);
      field.onChange([]);
    } else {
      setNoneChecked(false);
      const updatedConditions = e.target.checked
        ? field.value.filter((v) => v !== "none").concat(value)
        : field.value.filter((v) => v !== value);
      field.onChange(updatedConditions);
      setOtherChecked(true);
      if (updatedConditions.includes(value)) {
        delete errors.preExistingConditions;
      }
    }
  };


  const onSubmit = async (data) => {

    let dataArray = { ...storedData, data };
    const storedData1 = {
      members: []
    };

    const membersMap = new Map();  // Map to track unique members by a composite key

    // Process each item in the data array
    Object.values(dataArray).forEach((item) => {
      const member = {
        profile_image: item.profileImg.path,
        first_name: item.firstName,
        middle_name: item.middleName,
        last_name: item.lastName,
        relationship: item.relationship,
        dob: moment(item.dob).format('YYYY-MM-DD'),
        gender: item.gender,
        smoker: item.isSmoker,
        email: item.email,
        phone_number: item.phone,
        city: item.cityName,
        state: item.state,
        country: item.country,
        pre_existing_condition: item.preExistingConditions.map(condition => ({ condition_name: condition })),
        display_name_on_card: item.isUserConsent
      };

      // Create a composite key to identify each member uniquely
      const memberKey = `${member.first_name}|${member.middle_name}|${member.last_name}|${member.dob}|${member.email}`;
      if (membersMap.has(memberKey)) {

        const existingMember = membersMap.get(memberKey);   // If the member already exists, update their details
        Object.assign(existingMember, member);
      } else {
        membersMap.set(memberKey, member);   // If the member is new, add them to the map
      }
    });

    storedData1.members = Array.from(membersMap.values()); // Convert the map values to an array and assign to members
    const payload = { members: storedData1.members }; // Create the final payload with unique members



    setLoading(true);
    axiosInstance
      .post(ApiEndpoints.MEMBER.addMember, payload)
      .then((response) => {
        const responseData = response.data
        toast.success(responseData?.message)
        handleReset();
        localStorage.removeItem('memberDetailsId');
        navigate('/portfolio/added-member-list')
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });

    // return data;

  };

  const [autocompleteKey, setAutocompleteKey] = useState(0);

  const handleReset = () => {
    reset({
      profileImg: null,
      preExistingConditions: [],
      firstName: "",
      middleName: "",
      lastName: "",
      gender: 'female',
      isSmoker: 'yes',
      relationship: "",
      dob: null,
      country: null,
      state: null,
      phone: "",
      email: "",
      cityName: "",
      isUserConsent: false,
    });

    setValue('country', null);
    setValue('state', null);

    setAutocompleteKey(prevKey => prevKey + 1); // Force re-render by changing key

    setNoneChecked(false);
    setOtherChecked(false);
    // setUploadedFile(null);
    setIsUserConsent(false);
  };



  // const handleAddMember = async () => {
  //   handleSubmit(async (formData) => {
  //     const newEntry = { ...formData };
  //     const updatedEntries = storedData
  //       ? [...storedData, newEntry]
  //       : [newEntry];

  //     setStoredData(updatedEntries);
  //     setCurrentIndex(updatedEntries.length - 1);
  //     handleReset();
  //     // const allFormData = await onSubmit(updatedEntries);
  //   })();
  // };


  // const handleAddMember = async () => {
  //   handleSubmit(async (formData) => {
  //     const newEntry = { ...formData };
  //     const updatedEntries = storedData ? [...storedData, newEntry] : [newEntry];

  //     setStoredData(updatedEntries);
  //     setCurrentIndex(updatedEntries.length - 1);
  //     handleReset();
  //     setPreviousButtonDisabled(false); // Enable Previous button after adding new member
  //     // const allFormData = await onSubmit(updatedEntries);
  //   })();
  // };


  const handleAddMember = async () => {
    handleSubmit(async (formData) => {
      // Add an id to new entries if it doesn't exist
      if (!formData.id) {
        formData.id = new Date().getTime();
      }

      // Check if the form data matches an existing entry by id
      const existingIndex = storedData.findIndex((entry) => entry.id === formData.id);

      if (existingIndex !== -1) {
        // Update the existing entry if an id matches
        const updatedEntries = [...storedData];
        updatedEntries[existingIndex] = { ...updatedEntries[existingIndex], ...formData };
        setStoredData(updatedEntries);
      } else {
        // Add a new entry if no id matches
        const newEntry = { ...formData };
        const updatedEntries = storedData ? [...storedData, newEntry] : [newEntry];
        setStoredData(updatedEntries);
      }

      // Set current index to the last entry
      setCurrentIndex(storedData.length); // Always set current index to the last entry

      handleReset(); // Clear the form
      setPreviousButtonDisabled(false); // Enable Previous button after adding/updating member
    })();
  };






  // const handlePrevious = () => {
  //   if (currentIndex > 0) {
  //     const prevIndex = currentIndex - 1;
  //     setCurrentIndex(prevIndex);
  //     const prevEntry = storedData[prevIndex];
  //     if (prevEntry) {
  //       setValue("firstName", prevEntry.firstName);
  //       setValue("middleName", prevEntry.middleName);
  //       setValue("lastName", prevEntry.lastName);
  //       setValue("preExistingConditions", prevEntry.preExistingConditions);
  //       setValue("gender", prevEntry.gender);
  //       setValue("relationship", prevEntry.relationship);
  //       setValue("email", prevEntry.email);
  //       setValue("phone", prevEntry.phone);
  //       setValue("state", prevEntry.state);
  //       setValue("cityName", prevEntry.cityName);
  //       setValue("country", prevEntry.country);
  //       setValue("isSmoker", prevEntry.isSmoker);
  //       setIsUserConsent(prevEntry.isSmoker);
  //       setValue("dob", prevEntry.dob);
  //       setValue("isUserConsent", prevEntry.isUserConsent);
  //       setValue("profileImg", prevEntry.profileImg);

  //       const newCheckboxState = { ...checkboxState };
  //       checkboxOptions.forEach(({ value }) => {
  //         newCheckboxState[value] = prevEntry.preExistingConditions.includes(
  //           value
  //         );
  //       });
  //       setCheckboxState(newCheckboxState);
  //       setNoneChecked(prevEntry.preExistingConditions.includes("none"));
  //       setOtherChecked(
  //         prevEntry.preExistingConditions.length > 0 &&
  //         !prevEntry.preExistingConditions.includes("none")
  //       );

  //       if (!previousClicked && showDialog) {
  //         setPreviousClicked(true);
  //         setShowDialog(false);
  //         setConfirmDialogOpen(true);
  //       }
  //     }
  //   }
  // };



  // const handlePrevious = () => {
  //   if (firstPreviousClick) {
  //     setConfirmDialogOpen(true); // Open the dialog on first click
  //     setFirstPreviousClick(false); // Set firstPreviousClick to false after first click
  //   } else {
  //     // Add your handlePrevious logic here
  //     if (currentIndex > 0) {
  //       const prevIndex = currentIndex - 1;
  //       setCurrentIndex(prevIndex);
  //       const prevEntry = storedData[prevIndex];
  //       if (prevEntry) {
  //         setValue("firstName", prevEntry.firstName);
  //         setValue("middleName", prevEntry.middleName);
  //         setValue("lastName", prevEntry.lastName);
  //         setValue("preExistingConditions", prevEntry.preExistingConditions);
  //         setValue("gender", prevEntry.gender);
  //         setValue("relationship", prevEntry.relationship);
  //         setValue("email", prevEntry.email);
  //         setValue("phone", prevEntry.phone);
  //         setValue("state", prevEntry.state);
  //         setValue("cityName", prevEntry.cityName);
  //         setValue("country", prevEntry.country);
  //         setValue("isSmoker", prevEntry.isSmoker);
  //         setValue("dob", prevEntry.dob);
  //         setValue("profileImg", prevEntry.profileImg);
  //         setValue("isUserConsent", prevEntry.isUserConsent);
  //         setIsUserConsent(prevEntry.isUserConsent);

  //         const newCheckboxState = { ...checkboxState };
  //         checkboxOptions.forEach(({ value }) => {
  //           newCheckboxState[value] = prevEntry.preExistingConditions.includes(
  //             value
  //           );
  //         });
  //         setCheckboxState(newCheckboxState);
  //         setNoneChecked(prevEntry.preExistingConditions.includes("none"));
  //         setOtherChecked(
  //           prevEntry.preExistingConditions.length > 0 &&
  //           !prevEntry.preExistingConditions.includes("none")
  //         );

  //         if (!previousClicked && showDialog) {
  //           setPreviousClicked(true);
  //           setShowDialog(false);
  //           setConfirmDialogOpen(true);
  //         }
  //       }
  //     }
  //   }
  // };

  // const handleNext = () => {
  //   if (currentIndex < storedData.length - 1 && currentIndex < 2) {
  //     const nextIndex = currentIndex + 1;
  //     setCurrentIndex(nextIndex);
  //     const nextEntry = storedData[nextIndex];
  //     if (nextEntry) {
  //       setValue("firstName", nextEntry.firstName);
  //       setValue("middleName", nextEntry.middleName);
  //       setValue("lastName", nextEntry.lastName);
  //       setValue("preExistingConditions", nextEntry.preExistingConditions);
  //       setValue("gender", nextEntry.gender);
  //       setValue("relationship", nextEntry.relationship);
  //       setValue("email", nextEntry.email);
  //       setValue("phone", nextEntry.phone);
  //       setValue("cityName", nextEntry.cityName);
  //       setValue("state", nextEntry.state);
  //       setValue("country", nextEntry.country);
  //       setValue("isSmoker", nextEntry.isSmoker);
  //       setValue("dob", nextEntry.dob);
  //       setValue("profileImg", nextEntry.profileImg);
  //       setValue("isUserConsent", nextEntry.isUserConsent);
  //       setIsUserConsent(nextEntry.isUserConsent);

  //       const newCheckboxState = { ...checkboxState };
  //       checkboxOptions.forEach(({ value }) => {
  //         newCheckboxState[value] = nextEntry.preExistingConditions.includes(
  //           value
  //         );
  //       });
  //       setCheckboxState(newCheckboxState);
  //       setNoneChecked(nextEntry.preExistingConditions.includes("none"));
  //       setOtherChecked(
  //         nextEntry.preExistingConditions.length > 0 &&
  //         !nextEntry.preExistingConditions.includes("none")
  //       );
  //     }
  //   }
  // };


  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(false);

  const handlePrevious = async () => {
    if (storedData.length === 1) {
      setPreviousButtonDisabled(true);
    }

    if (firstPreviousClick) {
      setConfirmDialogOpen(true); // Open the dialog on first click
      setFirstPreviousClick(false); // Set firstPreviousClick to false after first click
    } else {
      // Calculate the previous index
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;

      // Update currentIndex state
      setCurrentIndex(prevIndex);

      // Populate form with previous entry data
      const prevEntry = storedData[prevIndex];
      if (prevEntry) {
        setValue("id", prevEntry.id);
        setValue("firstName", prevEntry.firstName);
        setValue("middleName", prevEntry.middleName);
        setValue("lastName", prevEntry.lastName);
        setValue("preExistingConditions", prevEntry.preExistingConditions);
        setValue("gender", prevEntry.gender);
        setValue("relationship", prevEntry.relationship);
        setValue("email", prevEntry.email);
        setValue("phone", prevEntry.phone);
        setValue("cityName", prevEntry.cityName);
        // setValue("state", prevEntry.state);
        // setValue("country", prevEntry.country);
        setValue("isSmoker", prevEntry.isSmoker);
        setValue("dob", prevEntry.dob);
        setValue("profileImg", prevEntry.profileImg);
        setValue("isUserConsent", prevEntry.isUserConsent);
        setIsUserConsent(prevEntry.isUserConsent);

        // Update checkbox state
        const newCheckboxState = { ...checkboxState };
        checkboxOptions.forEach(({ value }) => {
          newCheckboxState[value] = prevEntry.preExistingConditions.includes(value);
        });
        setCheckboxState(newCheckboxState);
        setNoneChecked(prevEntry.preExistingConditions.includes("none"));
        setOtherChecked(
          prevEntry.preExistingConditions.length > 0 &&
          !prevEntry.preExistingConditions.includes("none")
        );

        const selectedCountry = countryList.find(country =>
          country.name.toLowerCase() === prevEntry.country.toLowerCase().trim()
        );

        if (selectedCountry) {
          setValue("country", selectedCountry.name);
          const states = await getStateList(selectedCountry.iso2);
          const selectedState = states?.find(state =>
            state.name.toLowerCase() === prevEntry.state.toLowerCase().trim()
          );

          if (selectedState) {
            setValue("state", selectedState.name);
          }
        }
        if (!previousClicked && showDialog) {
          setPreviousClicked(true);
          setShowDialog(false);
          setConfirmDialogOpen(true);
        }
      }
    }
  };

  const handleNext = async () => {
    if (currentIndex < storedData.length - 1) {
      const nextIndex = currentIndex + 1;

      // Update currentIndex state
      setCurrentIndex(nextIndex);

      // Populate form with next entry data
      const nextEntry = storedData[nextIndex];
      if (nextEntry) {
        setValue("id", nextEntry.id);
        setValue("firstName", nextEntry.firstName);
        setValue("middleName", nextEntry.middleName);
        setValue("lastName", nextEntry.lastName);
        setValue("preExistingConditions", nextEntry.preExistingConditions);
        setValue("gender", nextEntry.gender);
        setValue("relationship", nextEntry.relationship);
        setValue("email", nextEntry.email);
        setValue("phone", nextEntry.phone);
        setValue("cityName", nextEntry.cityName);
        // setValue("state", nextEntry.state);
        // setValue("country", nextEntry.country);
        setValue("isSmoker", nextEntry.isSmoker);
        setValue("dob", nextEntry.dob);
        setValue("profileImg", nextEntry.profileImg);
        setValue("isUserConsent", nextEntry.isUserConsent);
        setIsUserConsent(nextEntry.isUserConsent);

        // Update checkbox state
        const newCheckboxState = { ...checkboxState };
        checkboxOptions.forEach(({ value }) => {
          newCheckboxState[value] = nextEntry.preExistingConditions.includes(value);
        });
        setCheckboxState(newCheckboxState);
        setNoneChecked(nextEntry.preExistingConditions.includes("none"));
        setOtherChecked(
          nextEntry.preExistingConditions.length > 0 &&
          !nextEntry.preExistingConditions.includes("none")
        );


        const selectedCountry = countryList.find(country =>
          country.name.toLowerCase() === nextEntry.country.toLowerCase().trim()
        );

        if (selectedCountry) {
          setValue("country", selectedCountry.name);
          const states = await getStateList(selectedCountry.iso2);

          const selectedState = states?.find(state =>
            state.name.toLowerCase() === nextEntry.state.toLowerCase().trim()
          );

          if (selectedState) {
            setValue("state", selectedState.name);
          }
        }

        if (!nextClicked && showDialog) {
          setNextClicked(true);
          setShowDialog(false);
          setConfirmDialogOpen(true);
        }
      }
    }
  };


  const toggleConfirmDialog = (e, data) => {
    setConfirmDialogOpen(prev => !prev)
  }


  const handleConfirm = async () => {
    await handlePrevious(); // Invoke handlePrevious after confirming
    toggleConfirmDialog(); // Close the dialog
    setFirstPreviousClick(false); // Set firstPreviousClick to false so dialog won't appear again
  };

  const handleCancel = () => {
    toggleConfirmDialog(); // Close the dialog without invoking handlePrevious
    setFirstPreviousClick(true); // Set firstPreviousClick to false so dialog won't appear again
    setPreviousButtonDisabled(false)
  };


  return (
    <>
      {loading ? <Loader /> :
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <form noValidate ref={formRef} onSubmit={handleSubmit(onSubmit)} className='auth__form'>
                <CardContent>
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant='fm-p1' color='neutral.80'>
                        <Translations text='Kindly enter necessary details to create your account.' />
                      </Typography>
                      <Box>
                        {storedData.length > 0 && (
                          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Typography variant='fm-p1' color='neutral.80' fontWeight={400}>
                              {`Member #${currentIndex + 1}`}
                            </Typography>
                            <IconButton
                              onClick={handlePrevious}
                              disabled={previousButtonDisabled || (currentIndex === 0 && storedData.length > 1)}
                              sx={{
                                backgroundColor: '#7338AC',
                                '&:hover': {
                                  backgroundColor: '#582a84',
                                },
                                '&.Mui-disabled': {
                                  backgroundColor: '#7338AC',
                                  opacity: 0.5,
                                },
                              }}
                            >
                              <ArrowBackIosNewRoundedIcon fontSize='small' sx={{ color: '#ffffff' }} />
                            </IconButton>
                            <IconButton
                              onClick={handleNext}
                              disabled={currentIndex === storedData.length - 1}
                              sx={{
                                backgroundColor: '#7338AC',
                                '&:hover': {
                                  backgroundColor: '#582a84',
                                },
                                '&.Mui-disabled': {
                                  backgroundColor: '#7338AC',
                                  opacity: 0.5,
                                },
                              }}
                            >
                              <ArrowForwardIosRoundedIcon fontSize='small' sx={{ color: '#ffffff' }} />
                            </IconButton>
                          </Box>
                        )}
                      </Box>



                    </Box>

                    <Box mt={5} sx={{ display: 'flex' }}>
                      <Typography variant='fm-h6' color='neutral.80' fontWeight={600}>
                        <Translations text='Personal Information' />
                      </Typography>
                    </Box>
                    <Grid item container spacing={6} mt={'3px'}>
                      <Grid item xs={12} md={2}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor='profileImg' required>
                            {translation.translate('form.profileImg.label')}
                          </FormLabel>
                          {/* <Controller
                          name='profileImg'
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomDropzone
                              title={<Translations text='form.profileImg.label' />}
                              // subtitle={<Translations text='form.profileImg.label3' />}
                              files={value}
                              onChange={onChange}
                              height="200px"
                              width={{ md: '180px', xs: 'auto' }}
                              accept={ProfileSupportedDropzone}
                            />
                          )}
                        /> */}
                          <Controller
                            name="profileImg"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <CustomDropzone
                                  title={<Translations text='form.profileImg.label' />}
                                  // subtitle={<Translations text='form.profileImg.label3' />}
                                  files={value}
                                  onChange={(acceptedFiles) => {
                                    onChange(acceptedFiles);
                                    // setUploadedFile(acceptedFiles?.name || null); 
                                  }}
                                  height="200px"
                                  width={{ md: '180px', xs: 'auto' }}
                                  accept={ProfileSupportedDropzone}
                                />
                                {/* {uploadedFile && <p>Uploaded File: {uploadedFile}</p>}  */}
                              </>
                            )}
                          />

                          {errors.profileImg && (
                            <FormHelperText sx={{ color: 'error.main' }}>{errors.profileImg.message}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item container xs={12} md={10} direction={'column'} spacing={3}>
                        <Grid item container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                              <FormLabel htmlFor='firstName' required>
                                {translation.translate('form.firstName.label')}
                              </FormLabel>
                              <Controller
                                name='firstName'
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    error={Boolean(errors.firstName)}
                                    value={value}
                                    onChange={onChange}
                                    autoFocus
                                    id='firstName'
                                    placeholder={translation.translate('form.firstName.placeholder')}
                                  />
                                )}
                              />
                              {errors.firstName && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                  <Translations text={`form.firstName.error.${errors.firstName.message}`} />
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                              <FormLabel htmlFor='middleName' required>
                                {translation.translate('form.middleName.label')}
                              </FormLabel>
                              <Controller
                                name='middleName'
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    error={Boolean(errors.middleName)}
                                    value={value}
                                    onChange={onChange}
                                    autoFocus
                                    id='middleName'
                                    placeholder={translation.translate('form.middleName.placeholder')}
                                  />
                                )}
                              />
                              {errors.middleName && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                  <Translations text={`form.middleName.error.${errors.middleName.message}`} />
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                              <FormLabel htmlFor='lastName' required>
                                {translation.translate('form.lastName.label')}
                              </FormLabel>
                              <Controller
                                name='lastName'
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    error={Boolean(errors.lastName)}
                                    value={value}
                                    onChange={onChange}
                                    autoFocus
                                    id='lastName'
                                    placeholder={translation.translate('form.lastName.placeholder')}
                                  />
                                )}
                              />
                              {errors.lastName && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                  <Translations text={`form.lastName.error.${errors.lastName.message}`} />
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item xs={6} md={6}>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                              <FormLabel htmlFor='dob' required>
                                {translation.translate('form.dob.label')}
                              </FormLabel>
                              <Controller
                                name='dob' // Adjust the field name as needed
                                control={control}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                  <DatePickerWrapper>
                                    <DatePicker
                                      selected={value ? new Date(value) : null}
                                      onChange={(date) => onChange(date.toISOString())}
                                      onBlur={onBlur}
                                      dateFormat='dd/MM/yyyy'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      maxDate={new Date()}
                                      customInput={
                                        <TextField
                                          error={Boolean(error)} // Use Boolean(error) to set error prop
                                          fullWidth
                                          variant='outlined'
                                          placeholder='DD/MM/YYYY'
                                          value={value}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position='start'>
                                                <CalendarTodayOutlinedIcon />
                                              </InputAdornment>
                                            ),
                                            style: {
                                              borderRadius: '142px',
                                            },
                                          }}
                                        />
                                      }
                                    />
                                  </DatePickerWrapper>
                                )}
                              />
                              {errors.dob && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                  <Translations text={`form.dob.error.${errors.dob.message}`} />
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                              <FormLabel htmlFor='relationship' required>
                                {translation.translate('form.relationship.label')}
                              </FormLabel>
                              <Controller
                                name='relationship'
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    variant='outlined'
                                    error={Boolean(errors.relationship)}
                                    id='relationship'
                                    placeholder={translation.translate('form.relationship.placeholder')}
                                  >
                                    {Relation.map((item, index) => {
                                      return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    })}
                                  </Select>
                                )}
                              />
                              {errors.relationship && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                  <Translations text={`form.relationship.error.${errors.relationship.message}`} />
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                              <FormLabel id='demo-radio-buttons-group-label'>
                                {translation.translate('form.Gender.label')}
                              </FormLabel>
                              <Controller
                                name='gender'
                                control={control}
                                defaultValue='female'
                                render={({ field }) => (
                                  <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    name='gender'
                                    row
                                    value={field.value}
                                    onChange={e => setValue('gender', e.target.value)}
                                  >
                                    <CustomRadioLabel
                                      value='male'
                                      control={<Radio />}
                                      label={<Translations text={`form.Gender.label1`} />}
                                      checked={field.value === 'male'}
                                    />
                                    <CustomRadioLabel
                                      value='female'
                                      control={<Radio />}
                                      label={<Translations text={`form.Gender.label2`} />}
                                      checked={field.value === 'female'}
                                    />
                                    <CustomRadioLabel
                                      value='other'
                                      control={<Radio />}
                                      label={<Translations text={`form.Gender.label3`} />}
                                      checked={field.value === 'other'}
                                    />
                                  </RadioGroup>
                                )}
                              />
                              {errors.gender && <span>{errors.gender.message}</span>}
                            </FormControl>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                              <FormLabel id='demo-radio-buttons-group-label'>
                                {translation.translate('form.Smoker.label')}
                              </FormLabel>
                              <Controller
                                name='isSmoker'
                                control={control}
                                defaultValue='Yes'
                                render={({ field }) => (
                                  <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    name='Smoker'
                                    row
                                    value={field.value}
                                    onChange={e => setValue('isSmoker', e.target.value)}
                                  >
                                    <CustomRadioLabel
                                      value='yes'
                                      control={<Radio />}
                                      label={<Translations text={`form.Smoker.label1`} />}
                                      checked={field.value === 'yes'}
                                    />
                                    <CustomRadioLabel
                                      value='no'
                                      control={<Radio />}
                                      label={<Translations text={`form.Smoker.label2`} />}
                                      checked={field.value === 'no'}
                                    />
                                  </RadioGroup>
                                )}
                              />
                              {errors.isSmoker && <span>{errors.isSmoker.message}</span>}
                            </FormControl>
                            <Grid item>
                              <Typography variant='fm-16' fontWeight={400} color='#7E7585'>
                                <Translations text='If the right option is not selected and person is diagnosed with lung cancer as a result of tobacco, their illness shall not be covered' />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box mt={5}>
                      <Typography variant='fm-h6' color='neutral.80' fontWeight={600}>
                        <Translations text='Contact Information' />
                      </Typography>
                    </Box>
                    <Grid item container spacing={3} mt={'3px'}>
                      <Grid item md={6} xs={12}>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <FormLabel htmlFor='email' required>
                            {translation.translate('form.email.label')}
                          </FormLabel>
                          <Controller
                            name='email'
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                error={Boolean(errors.email)}
                                type='email'
                                autoFocus
                                id='email'
                                value={value}
                                onChange={onChange}
                                placeholder={translation.translate('form.email.placeholder')}
                              />
                            )}
                          />
                          {errors.email && (
                            <FormHelperText sx={{ color: 'error.main' }}>
                              <Translations text={`form.email.error.${errors.email.message}`} />
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <FormLabel htmlFor='phone' required>
                            {translation.translate('form.phone.label')}
                          </FormLabel>
                          <Controller
                            name='phone'
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                error={Boolean(errors.phone)}
                                // {...field}
                                type="tel"
                                variant='outlined'
                                fullWidth
                                placeholder={translation.translate('form.phone.placeholder')}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          {errors.phone && (
                            <FormHelperText sx={{ color: 'error.main' }}>
                              <Translations text={`form.phone.error.${errors.phone.message}`} />
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item container spacing={3}>
                        <Grid item md={4} xs={12}>
                          <FormControl fullWidth sx={{ mb: 4 }}>
                            <FormLabel htmlFor='country' required>
                              {translation.translate('form.country.label')}
                            </FormLabel>
                            <Controller
                              name='country'
                              control={control}
                              render={({ field }) => (
                                <Autocomplete
                                  key={`country-${autocompleteKey}`} // Use unique key
                                  id="country-autocomplete"
                                  options={countryList}
                                  getOptionLabel={(option) => option.name}
                                  value={countryList.find(c => c.name === field.value) || null}
                                  onChange={(event, value) => {
                                    field.onChange(value ? value?.name : null)
                                    handleCountryChange(event, value)
                                  }
                                  }
                                  renderOption={(props, option) => (
                                    <li {...props}>
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Country"
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {countryLoading && <CircularProgress size={20} color="inherit" />}
                                            {params.InputProps.endAdornment}
                                          </>
                                        )
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                            {errors.country && (
                              <FormHelperText sx={{ color: 'error.main' }}>
                                <Translations text={`form.country.error.${errors.country.message}`} />
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <FormControl fullWidth sx={{ mb: 4 }}>
                            <FormLabel htmlFor='state' required>
                              {translation.translate('form.state.label')}
                            </FormLabel>
                            <Controller
                              name='state'
                              control={control}
                              render={({ field }) => (
                                <Autocomplete
                                  key={`state-${autocompleteKey}`} // Use unique key
                                  id="state-autocomplete"
                                  options={statesList}
                                  getOptionLabel={(option) => option.name}
                                  value={statesList.find(c => c.name === field.value) || null}
                                  onChange={(event, value) => {
                                    field.onChange(value ? value?.name : null)
                                  }}
                                  renderOption={(props, option) => (
                                    <li {...props}>
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="State"
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {statesLoading && <CircularProgress size={20} color="inherit" />}
                                            {params.InputProps.endAdornment}
                                          </>
                                        )
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                            {errors.state && (
                              <FormHelperText sx={{ color: 'error.main' }}>
                                <Translations text={`form.state.error.${errors.state.message}`} />
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FormControl fullWidth sx={{ mb: 4 }}>
                            <FormLabel htmlFor='cityName' required>
                              {translation.translate('form.cityName.label')}
                            </FormLabel>
                            <Controller
                              name='cityName'
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  error={Boolean(errors.cityName)}
                                  value={value}
                                  onChange={onChange}
                                  autoFocus
                                  id='cityName'
                                  placeholder={translation.translate('form.cityName.placeholder')}
                                />
                              )}
                            />
                            {errors.cityName && (
                              <FormHelperText sx={{ color: 'error.main' }}>
                                <Translations text={`form.cityName.error.${errors.cityName.message}`} />
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box mt={5}>
                      <Typography variant='fm-h6' color='neutral.80' fontWeight={600}>
                        <Translations text='Pre-existing Condition' />
                      </Typography>
                    </Box>
                    <Grid item container spacing={3} mt={'3px'}>
                      <Grid item md={6} xs={12}>
                        <FormControl
                          fullWidth
                          sx={{ mb: 4 }}
                        // error={Boolean(errors.preExistingConditions)}
                        >
                          <FormGroup row>
                            {checkboxOptions.map(({ value, label }) => (
                              <FormControlLabel
                                key={value}
                                control={
                                  <Controller
                                    name="preExistingConditions"
                                    control={control}
                                    render={({ field }) => (
                                      <Checkbox
                                        {...field}
                                        value={value}
                                        checked={
                                          (value === "none" && noneChecked) ||
                                          (value !== "none" &&
                                            otherChecked &&
                                            field.value.includes(value))
                                        }
                                        onChange={handleCheckboxChange(value, field)}
                                        color="primary"
                                      />
                                    )}
                                  />
                                }
                                label={<Typography> <Translations text={label} /> </Typography>}
                              />
                            ))}
                          </FormGroup>
                          {errors.preExistingConditions && (
                            <FormHelperText sx={{ color: "error.main" }}>
                              {errors.preExistingConditions.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                </CardContent>

                <CardContent>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Controller
                        name='isUserConsent'
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                // {...field}
                                color='primary'
                                checked={isUserConsent}
                                onChange={(e) => {
                                  setIsUserConsent(e.target.checked);
                                  onChange(e.target.checked)
                                  if (e.target.checked) {
                                    setValue('isUserConsent', true); // Remove error message for checkbox
                                  }
                                }}
                              />
                            }
                            label={
                              <Typography variant='fm-p2' color='theme.palette.text.light' sx={{ padding: '9px' }}>
                                {translation.translate('form.isUserConsent.label3')}
                              </Typography>
                            }
                          />
                        )}
                      />
                      {errors.isUserConsent && !isUserConsent && (
                        <FormHelperText sx={{ color: 'error.main' }}>
                          {errors.isUserConsent.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item container xs={12} mt={3} sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant='outlined' sx={{ padding: '12px 24px' }} name="addMember"
                        onClick={handleAddMember}>
                        <Typography variant="fm-p2" fontWeight={600}>
                          <Translations text='Add More Member' />
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}
                      sx={{ display: 'flex', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}
                    >
                      <Button
                        loading={loading} type="submit" sx={{ md: { width: '165px' }, xs: { width: '100%' }, padding: '12px 24px' }} variant='contained'>
                        <Typography variant="fm-p2" fontWeight={600}>
                          <Translations text='Submit' />
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
        </Grid>
      }

      <MemberConfirmDialog
        open={isConfirmDialogOpen}
        toggle={toggleConfirmDialog}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />

    </>
  )
}
export default AddMemberForm
