import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import Translations from 'src/layouts/components/Translations'
import { FormValidationMessages } from 'src/constants/form.const'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import { toastError, toastSuccess } from 'src/utils/utils'
import { useAuth } from 'src/hooks/useAuth'
import { Navigate, useNavigate } from 'react-router-dom'

const validationSchema = yup.object().shape({
  isUserConsent: yup
    .bool()
    .oneOf([true], FormValidationMessages.IS_USER_CONSENT.required)
    .required(FormValidationMessages.IS_USER_CONSENT.required)
})

function DeclarationConsentDialog(props) {
  const translation = useCustomTranslation()
  const { open, toggle, payload, redirectLink } = props
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const auth = useAuth()
  const onSubmit = async data => {
    navigate(redirectLink)
  }
  return (
    <>
      <Dialog fullWidth maxWidth='lg' open={open} onClose={toggle}>
        <DialogContent>
          <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <Typography
                  variant='fm-h5'
                  color='neutral.80'
                  fontWeight='600'
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  {translation.translate('form.privacyDialogText.heading')}
                </Typography>
                <Typography variant='fm-p2' color='neutral.70'>
                  {translation.translate('form.privacyDialogText.value1')}
                </Typography>
                <Typography variant='fm-p2' color='neutral.70'>
                  {translation.translate('form.privacyDialogText.value2')}
                </Typography>
                <Typography variant='fm-p2' color='neutral.70'>
                  {translation.translate('form.privacyDialogText.value3')}
                </Typography>
                <Typography variant='fm-p2' color='neutral.70'>
                  {translation.translate('form.privacyDialogText.value4')}
                </Typography>
                <Typography variant='fm-p2' color='neutral.70'>
                  {translation.translate('form.privacyDialogText.value5')}
                </Typography>
                <Typography variant='fm-p2' color='neutral.70'>
                  {translation.translate('form.privacyDialogText.value6')}
                </Typography>
                <Typography variant='fm-p2' color='neutral.70'>
                  {translation.translate('form.privacyDialogText.value7')}
                </Typography>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='isUserConsent'
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start'
                        }}
                        control={<Checkbox {...field} color='primary' />}
                        label={
                          <Typography sx={{ padding: '9px' }}>
                            {translation.translate('form.isUserConsent.label2')}
                          </Typography>
                        }
                      />
                    )}
                  />
                  {errors.isUserConsent && (
                    <FormHelperText sx={{ color: 'error.main' }}>
                      <Translations text={`form.isUserConsent.error.${errors.isUserConsent.message}`} />
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Button
                loading={loading}
                size='large'
                type='submit'
                variant='contained'
                sx={{ width: '218px', mt: '12px' }}
              >
                <Translations text={'Continue'} />
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default DeclarationConsentDialog
