import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useCustomTranslation from "src/@core/hooks/useCustomTranslation";

const PaymentConfirm = ({ open, toggle, redirectUrl }) => {

    const translation = useCustomTranslation()
    const navigate = useNavigate();

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={open}
                onClose={toggle}
                PaperProps={{ sx: { borderRadius: '24px' } }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        py: '20px',
                        px: '15px',
                        textAlign: 'center',
                    }}
                >
                    <DialogTitle variant="fm-h6" fontWeight={600}>{translation.translate("Payment Success")}</DialogTitle>
                    <DialogContent variant="fm-p3" fontWeight={400}>
                        Plan Purchase Successfully.
                    </DialogContent>

                    <DialogActions sx={{ display: "flex", gap: "10px", width: "100%" }} >
                        <Button 
                        onClick={() => {
                            toggle()
                            // navigate(redirectUrl)
                        }}
                            variant="contained" sx={{ padding: "12px", width: "100%" }}>
                            <Typography variant="fm-p2">{translation.translate("Okay")}</Typography>
                        </Button>

                    </DialogActions>
                </Card>
            </Dialog>
        </>
    )
}

export default PaymentConfirm